<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-parking"></i>{{profile.incident_detail.incident_was_parked}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>
                    {{profile.incident_detail.was_parked_until}} {{formatDate(incidentInfo.service_desk_was_suspended_until)}}
                    <br /> <br />
                    {{profile.incident_detail.reason_for_park}}: {{incidentInfo.service_desk_suspended_reason}}
                    <br /><br />
                </p>
                <button class="button button--cta button--icon button--right" v-on:click="viewIncident()">{{profile.incident_detail.view_incident}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
export default {
    mixins: [setLocale],
    name: 'wasParkedModal',
    props: ['incidentId', 'incidentInfo'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        viewIncident(){
            let self = this;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            fetch('/api/v1/incident/acknowledgeparked', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.closeModal();
                self.$emit('success');
            });

        },
        formatDate(date) {
            return moment(date).add(1, 'days').format('DD/MM/YYYY');
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
