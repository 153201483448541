<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>{{profile.assets.create_assettype}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createAssetType()">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.name.length, error: nameError}">
                                <label style="font-weight: 500;">{{profile.dashboard.building_manager.message_table.name}}</label>
                                <input type="text" data-testid="newAssetTypeName" class="form-control" v-model="fields.name" style="margin-bottom: 10px;" ref="name" @input="nameCheck()" />
                            </div>
                        </div>
                    </div>
                    <span style="margin-bottom: 20px; display: block;"><b>{{profile.assets.fields}}</b> <i class="fas fa-info-circle" v-tooltip.right="profile.assets.define_tooltip" style="cursor: pointer;"></i>
                        <span style="float: right; margin-top: -2px; cursor: pointer;" data-testid="addFieldButton" @click="fieldCount++" v-if="fieldCount < 11"><i class="fa-solid fa-plus" style="color: #0867b3; font-size: 20px;"></i></span>
                    </span>
                    <div class="row" v-if="fieldCount > 0">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_a}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 1</label>
                                <input type="text" data-testid="newFieldATitel" class="form-control" v-model="fields.extra_description_a" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_a_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 1 {{profile.assets.default_value}}</label>
                                <input type="text" data-testid="newFieldADefaultValue" class="form-control" v-model="fields.extra_description_a_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 1">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_b}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 2</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_b" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_b_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 2 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_b_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 2">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_c}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 3</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_c" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_c_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 3 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_c_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 3">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_d}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 4</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_d" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_d_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 4 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_d_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 4">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_e}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 5</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_e" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_e_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 5 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_e_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 5">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_f}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 6</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_f" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_f_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 6 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_f_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 6">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_g}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 7</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_g" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_g_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 7 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_g_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 7">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_h}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 8</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_h" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_h_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 8 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_h_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 8">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_i}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 9</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_i" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_i_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 9 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_i_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="fieldCount > 9">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_j}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 10</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_j" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.extra_description_j_default}">
                                <label style="font-weight: 500;">{{profile.assets.field}} 10 {{profile.assets.default_value}}</label>
                                <input type="text" class="form-control" v-model="fields.extra_description_j_default" style="margin-bottom: 10px;" />
                            </div>
                        </div>
                    </div>
                    <button type="submit" data-testid="newAssetTypeSubmit" class="button button--icon"><i class="fa-solid fa-plus"></i>{{profile.assets.create_assettype}}</button>
                    <br /><br />
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            fieldCount: 0,
            nameError: false,
            fields: {
                name: '',
                extra_description_a: '',
                extra_description_a_default: '',
                extra_description_b: '',
                extra_description_b_default: '',
                extra_description_c: '',
                extra_description_c_default: '',
                extra_description_d: '',
                extra_description_d_default: '',
                extra_description_e: '',
                extra_description_e_default: '',
                extra_description_f: '',
                extra_description_f_default: '',
                extra_description_g: '',
                extra_description_g_default: '',
                extra_description_h: '',
                extra_description_h_default: '',
                extra_description_i: '',
                extra_description_i_default: '',
                extra_description_j: '',
                extra_description_j_default: ''
            }
        }
    },
    computed: mapState(['bmId']),
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        nameCheck() {
            if (this.fields.name.length) {
                this.nameError = false;
            }
        },
        createAssetType() {
            if (this.fields.name.length) {
                let self = this;
                const data = new URLSearchParams();
                let nameParam = 'data[0][display_name]';
                let constantNameParam = 'data[0][constant_name]';
                let bmParam = 'data[0][id_building_manager]';
                let assetTypeFieldAParam = 'data[0][extra_description_a]';
                let assetTypeFieldBParam = 'data[0][extra_description_b]';
                let assetTypeFieldCParam = 'data[0][extra_description_c]';
                let assetTypeFieldDParam = 'data[0][extra_description_d]';
                let assetTypeFieldEParam = 'data[0][extra_description_e]';
                let assetTypeFieldFParam = 'data[0][extra_description_f]';
                let assetTypeFieldGParam = 'data[0][extra_description_g]';
                let assetTypeFieldHParam = 'data[0][extra_description_h]';
                let assetTypeFieldIParam = 'data[0][extra_description_i]';
                let assetTypeFieldJParam = 'data[0][extra_description_j]';
                let assetTypeFieldADefaultParam = 'data[0][extra_description_a_default]';
                let assetTypeFieldBDefaultParam = 'data[0][extra_description_b_default]';
                let assetTypeFieldCDefaultParam = 'data[0][extra_description_c_default]';
                let assetTypeFieldDDefaultParam = 'data[0][extra_description_d_default]';
                let assetTypeFieldEDefaultParam = 'data[0][extra_description_e_default]';
                let assetTypeFieldFDefaultParam = 'data[0][extra_description_f_default]';
                let assetTypeFieldGDefaultParam = 'data[0][extra_description_g_default]';
                let assetTypeFieldHDefaultParam = 'data[0][extra_description_h_default]';
                let assetTypeFieldIDefaultParam = 'data[0][extra_description_j_default]';
                let assetTypeFieldJDefaultParam = 'data[0][extra_description_j_default]';
                data.append('action', 'create');
                data.append(nameParam, self.fields.name);
                data.append(constantNameParam, self.fields.name);
                data.append(bmParam, this.bmId);
                if (self.fields.extra_description_a) {
                    data.append(assetTypeFieldAParam, self.fields.extra_description_a);
                }
                if (self.fields.extra_description_b) {
                    data.append(assetTypeFieldBParam, self.fields.extra_description_b);
                }
                if (self.fields.extra_description_c) {
                    data.append(assetTypeFieldCParam, self.fields.extra_description_c);
                }
                if (self.fields.extra_description_d) {
                    data.append(assetTypeFieldDParam, self.fields.extra_description_d);
                }
                if (self.fields.extra_description_e) {
                    data.append(assetTypeFieldEParam, self.fields.extra_description_e);
                }
                if (self.fields.extra_description_f) {
                    data.append(assetTypeFieldFParam, self.fields.extra_description_f);
                }
                if (self.fields.extra_description_g) {
                    data.append(assetTypeFieldGParam, self.fields.extra_description_g);
                }
                if (self.fields.extra_description_h) {
                    data.append(assetTypeFieldHParam, self.fields.extra_description_h);
                }
                if (self.fields.extra_description_i) {
                    data.append(assetTypeFieldIParam, self.fields.extra_description_i);
                }
                if (self.fields.extra_description_j) {
                    data.append(assetTypeFieldJParam, self.fields.extra_description_j);
                }
                if (self.fields.extra_description_a_default) {
                    data.append(assetTypeFieldADefaultParam, self.fields.extra_description_a_default);
                }
                if (self.fields.extra_description_b_default) {
                    data.append(assetTypeFieldBDefaultParam, self.fields.extra_description_b_default);
                }
                if (self.fields.extra_description_c_default) {
                    data.append(assetTypeFieldCDefaultParam, self.fields.extra_description_c_default);
                }
                if (self.fields.extra_description_d_default) {
                    data.append(assetTypeFieldDDefaultParam, self.fields.extra_description_d_default);
                }
                if (self.fields.extra_description_e_default) {
                    data.append(assetTypeFieldEDefaultParam, self.fields.extra_description_e_default);
                }
                if (self.fields.extra_description_f_default) {
                    data.append(assetTypeFieldFDefaultParam, self.fields.extra_description_f_default);
                }
                if (self.fields.extra_description_g_default) {
                    data.append(assetTypeFieldGDefaultParam, self.fields.extra_description_g_default);
                }
                if (self.fields.extra_description_h_default) {
                    data.append(assetTypeFieldHDefaultParam, self.fields.extra_description_h_default);
                }
                if (self.fields.extra_description_i_default) {
                    data.append(assetTypeFieldIDefaultParam, self.fields.extra_description_i_default);
                }
                if (self.fields.extra_description_j_default) {
                    data.append(assetTypeFieldJDefaultParam, self.fields.extra_description_j_default);
                }
                fetch('/api/v1/asset_type', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function() {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                });
            }
            else {
                this.nameError = true;
            }
        }
    }
}
</script>
