<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-portrait"></i>{{profile.incident_detail.take_over_incident}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.incident_detail.incident_who}} <b>{{userName}}</b>. {{profile.incident_detail.incident_take_over}}</p>
                <br />
                <section class="actions actions--full">
                    <div class="button-wrap">
                        <span class="button button--icon button--right" @click="viewIncident()" style="margin-left: 10px;"><i class="fas fa-eye"></i>{{profile.incident_detail.view_incident}}</span>
                        <span class="button button--icon button--cta button--right" data-testid="buttonTakeOverIncidentSubmit" style="margin-right: 10px;" @click="addHandler()"><i class="fas fa-portrait" id="takeoversubmit"></i>{{profile.incident_detail.take_over_incident}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'takeOverModal',
    data() {
        return {
            profile: {}
        }
    },
    props: ['userName', 'incidentInfo'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    beforeDestroy() {
        this.removeHandler();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        viewIncident() {
            this.$vToastify.warning(this.profile.toast.readonly_text, this.profile.toast.readonly);
            this.$emit('readonly');
            this.$emit('close');
        },
        addHandler() {
            window.addEventListener('click', this.takeOver);
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Incident detail - Incident overnemen',
            });
        },
        removeHandler() {
            window.removeEventListener('click', this.takeOver);
        },
        takeOver() {
            this.applicationLog();
            let queryString = window.location.search;
            let currentIncidentId = this.getUrlParameter('id');
            if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString()) {
                this.$emit('takeover');
                this.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            }
        },
        applicationLog() {
            try {
                const data = new URLSearchParams();
                data.append('message', 'Incident Takeover');
                data.append('id_incident', this.incidentInfo.id_incident);
                let self = this;
                fetch('/api/v1/applicationlog/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {});
            } catch (err) {
                console.log(err);
            }
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        }
    }
}
</script>
