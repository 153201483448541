<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="main-content" v-if="profile.statusses" :class="{readonly: readOnly}" @click="showReadOnlyMsg()">  
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col" style="position: relative;">
                        <Actions :incidentInfo="incidentInfo"
                                :admin="admin"
                                :hasMandates="hasMandates"
                                :readOnly="readOnly"
                                :forwardedReadOnly="forwardedReadOnly"
                                :readyToFinish="readyToFinish"
                                :newIncident="newIncident"
                                :readyForTakeover="readyForTakeover"
                                :readyForRestart="readyForRestart"
                                :manualCheckIn="manualCheckIn"
                                :manualCheckOut="manualCheckOut"
                                :manualCheckInOut="manualCheckInOut"
                                :noStatusChange="noStatusChange"
                                :categories="categories"
                                :commissionStart="commissionStart"
                                :buildingmanagers="buildingmanagers"
                                :actualName="actualName"
                                :hideRestart="hideRestart"
                                @startCommissioning="startCommissioning()"
                                @restartCommissioning="restartCommissioning()"
                                @repaircompanySubmit="repairCompanySubmit()"
                                @getIncidentInfo="getIncidentInfo()"
                                v-if="incidentInfo.id_incident && !isForwarded"
                        />
                    </div>
                </div>
                <MainBlock  :buildingmanager="buildingmanager"
                            :incidentInfo="incidentInfo"
                            :readOnly="readOnly"
                            :readyToFinish="readyToFinish"
                            :readyForRestart="readyForRestart"
                            :newIncident="newIncident"
                            :readyForTakeover="readyForTakeover"
                            :forwardedReadOnly="forwardedReadOnly"
                            :admin="admin"
                            :media="media"
                            :imagePresent="imagePresent"
                            :categories="categories"
                            :hideRestart="false"
                            :commissionStart="commissionStart"
                            @getIncidentInfo="getIncidentInfo()"
                            v-if="incidentInfo.id_incident" />
                <div class="row">
                    <div class="col-lg-8">
                        <MediaDocs :incidentInfo="incidentInfo" :incidentId="incidentId" :media="media" :docs="docs" :orders="orders" :mediacount="mediaCount" :movs="movs" :forwardedReadOnly="forwardedReadOnly" :readOnly="readOnly" :admin="admin" @getIncidentInfo="getIncidentInfo()" /> 
                    </div>
                    <div class="col-lg-4">
                        <Notes :readOnly="readOnly" :forwardedReadOnly="forwardedReadOnly" :incidentInfo="incidentInfo" v-if="incidentInfo.id_incident" />
                        <OtherDetails :incidentInfo="incidentInfo" :readOnly="readOnly" :admin="admin" @getIncidentInfo="getIncidentInfo()" />                      
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4" style="display: flex;">
                        <div class="block block--incident_detail location" style="width: 100%;">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1>{{profile.assets.assetmodal.place}}</h1>
                                <span class="label" v-if="incidentInfo.place_floor_name === 'Complex'" style="background: #424242; border-radius: 3px; padding: 3px 5px; font-size: 13px; color: white; font-weight: 500; float: right;">Complex</span>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <a :href="mapsUrl" target="_blank">
                                        <img :src="mapsApiUrl" id="address-map" />
                                    </a>
                                </div>
                            </div>
                            <p data-testid="locationInfo"  style="margin-bottom: 0;">
                                <router-link data-testid="locationNameLink" tag="a" v-bind:to="'/buildingmanager/location-detail?id_place=' + incidentInfo.id_place">{{incidentInfo.place_name}}
                                    <img src="@/assets/icons/flags/it.svg" v-if="incidentInfo.place_country === 'IT'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/de.svg" v-if="incidentInfo.place_country === 'DE'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/fr.svg" v-if="incidentInfo.place_country === 'FR'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/esp.svg" v-if="incidentInfo.place_country === 'ES'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/lu.svg" v-if="incidentInfo.place_country === 'LU'" style="width: 15px; margin-left: 5px;">
                                    <img src="@/assets/icons/flags/be.svg" v-if="incidentInfo.place_country === 'BE'" style="width: 15px; margin-left: 5px;">
                                </router-link>
                                {{incidentInfo.place_address}}<br />
                                {{incidentInfo.place_zipcode}} {{incidentInfo.place_city}}<br />
                                <span data-testid="locationFloor"  v-if="incidentInfo.place_floor_name !== 'Complex'">{{profile.incident_detail.floor_name}}: {{incidentInfo.place_floor_name}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-4" style="display: flex;">
                        <div class="block block--incident_detail location" style="width: 100%;">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1>{{profile.incidents.new_incident.id_asset}}</h1>
                                <span class="button button--neutral asset-history" style="margin-top: 10px; height: auto; line-height: 20px;padding: 5px 20px; max-width: 80%; bottom: auto; top: 3px;" data-testid="assetHistoryButton" @click="historyModalOpen = true">{{profile.incident_detail.asset_history}}</span>
                            </section>
                            <p v-if="readOnly || passedIncident"><router-link tag="a" v-bind:to="'/buildingmanager/asset-detail?asset=' + incidentInfo.id_asset">{{incidentInfo.asset_name}}</router-link></p>
                            <div class="dropdown-wrapper" v-if="!readOnly" @click="showReadOnlyMsg()">
                                <p class="dropdown-item-selected" v-if="selectedAsset.name" @click="switchAssetModalOpen = true" v-tooltip.left="profile.incident_detail.history_tooltip" style="margin-bottom: 0;">
                                    <i class="fas fa-cube"></i>&nbsp;&nbsp;
                                    <b><router-link tag="a" data-testid="assetNameLink" style="display: inline;" v-bind:to="'/buildingmanager/asset-detail?asset=' + incidentInfo.id_asset">{{selectedAsset.name}}</router-link></b>
                                    <br /><b>ID</b>&nbsp;&nbsp;{{selectedAsset.id_asset}}<br />
                                    <span v-if="selectedAsset.extra_description_a"><b>{{profile.assets.assetmodal.registration_number}}</b>&nbsp;&nbsp;{{selectedAsset.extra_description_a}}<br /></span>
                                    <span v-if="selectedAsset.extra_description_b"><b>{{profile.assets.assetmodal.contract_number}}</b>&nbsp;&nbsp;{{selectedAsset.extra_description_b}}<br /></span>
                                    <span v-if="selectedAsset.extra_description_c"><b>{{profile.assets.assetmodal.contract_form}}</b>&nbsp;&nbsp;{{selectedAsset.extra_description_c}}</span>
                                    <i class="fas fa-pencil-alt" v-if="!readOnly && !passedIncident" style="left: auto; right: 10px; color: black;"></i>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4" style="display: flex;">
                        <div class="block block--incident_detail location" style="width: 100%;" v-if="incidentInfo.id_incident">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1 v-if="incidentInfo.id_owner && vve">{{profile.assets.vve_short}}</h1>
                                <h1 v-if="incidentInfo.id_owner && !vve">{{profile.incident_detail.incident_owner}}</h1>
                            </section>
                            <p v-if="incidentInfo.id_owner && !passedIncident" style="margin-bottom: 0;"><router-link data-testid="ownerNameLink" tag="a" v-bind:to="'/buildingmanager/owner-detail?id=' + incidentInfo.id_owner">{{incidentInfo.owner_name}}</router-link></p>
                            <p v-if="passedIncident">{{incidentInfo.owner_name}}</p>
                            <span class="button button--icon button--small" @click="startOwnerChatModalOpen = true" style="float: left; width: 100%; text-align: center; margin: 10px 0 15px 0;" v-if="!incidentInfo.ownerUserChats.length && showStartUserChatButton"><i class="fas fa-comments"></i>{{profile.owner.start_chat_owner}}</span>
                            <label v-if="owner.description" style="clear: both; display: block;">{{profile.owners.ownertable.description}}</label>
                            <p data-testid="ownerCommentText" v-if="owner.description" style="margin-bottom: 65px;" v-html="owner.description"></p>
                            <span data-testid="ownerActiveVveButton" v-if="incidentInfo.id_owner && vve" class="button button--neutral asset-history" style="margin-top: 10px; height: auto; line-height: 20px; padding: 5px 20px; max-width: 80%; bottom: auto; top: 3px;" @click="ownerIncidentsModalOpen = true">{{profile.incident_detail.incidents_owner_vve}}</span>
                            <span data-testid="ownerActiveButton" v-if="incidentInfo.id_owner && !vve" class="button button--neutral asset-history" style="margin-top: 10px; height: auto; line-height: 20px; padding: 5px 20px; max-width: 80%;" @click="ownerIncidentsModalOpen = true">{{profile.incident_detail.incidents_owner}}</span>
                        </div>
                    </div>
                </div>
                <Commission :incidentInfo="incidentInfo"
                            :readOnly="readOnly"
                            :admin="admin"
                            :hasMandates="hasMandates"
                            :preStepChoice="preStepChoice"
                            :hasRepairCompany="hasRepairCompany"
                            :preferredSuppliers="preferredSuppliers"
                            :selectedRepairCompanyId="selectedRepairCompanyId"
                            :selectedInvoiceParty="selectedInvoiceParty"
                            :buildingmanager="buildingmanager"
                            :owner="owner"
                            :currentAppointment="currentAppointment"
                            :currentAppointmentDate="currentAppointmentDate"
                            :repairInfo="repairInfo"
                            @commissionRemoved="commissionRemoved()"
                            @getIncidentInfo="getIncidentInfo"
                            @getMechanics="getMechanics()"
                            @showCalendar="showCalendar"
                            v-if="commissionStart"
                />
                <div v-if="((incidentInfo.canbe_id_repair_company && selectedRepairCompanyId === incidentInfo.canbe_id_repair_company) && !forwardedReadOnly) || showSchedule" id="calendar">
                    <Schedule   :incidentInfo="incidentInfo"
                                :readOnly="readOnly"
                                :admin="admin"
                                :selectedMechanic="selectedMechanic"
                                :mechanics="mechanics"
                                :mechanicsNoS1mone="mechanicsNoS1mone"
                                @getMechanics="getMechanics"
                                @getIncidentInfo="getIncidentInfo"
                    />
                </div>
            </div>
        </div>
        <Chat :buildingmanager="buildingmanager" :incidentId="incidentId" :incidentInfo="incidentInfo" :incidentReadOnly="readOnly" :forwardedReadOnly="forwardedReadOnly" :canOwnerChat="canOwnerChat" @getIncidentInfo="getIncidentInfo()" ref="chatRef" v-if="!hideChat && incidentInfo.id_incident" />
        <HistoryModal v-if="historyModalOpen" :incidentInfo="incidentInfo" @close="historyModalOpen = false" />
        <OwnerIncidentsModal v-if="ownerIncidentsModalOpen" :incidentInfo="incidentInfo" :vve="vve" @close="ownerIncidentsModalOpen = false" />
        <StartOwnerChatModal v-if="startOwnerChatModalOpen" @close="startOwnerChatModalOpen = false" :incidentInfo="incidentInfo" />
        <SwitchAssetModal v-if="switchAssetModalOpen" @close="switchAssetModalOpen = false" @getIncidentInfo="getIncidentInfo()" :incidentInfo="incidentInfo" />
        <WasParkedModal v-if="wasParkedModalOpen" @close="wasParkedModalOpen = false" @success="getIncidentInfo()" :incidentId="incidentId" :incidentInfo="incidentInfo" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import Chat from '@/components/incidentdetail/Chat.vue'
import MainBlock from '@/components/incidentdetail/MainBlock.vue';
import MediaDocs from '@/components/incidentdetail/MediaDocs.vue';
import OtherDetails from '@/components/incidentdetail/OtherDetails.vue';
import Actions from '@/components/incidentdetail/Actions.vue';
import Commission from '@/components/incidentdetail/Commission.vue';
import Schedule from '@/components/incidentdetail/Schedule.vue';
import StartOwnerChatModal from "@/components/modals/StartOwnerChatModal.vue";
import SwitchAssetModal from "@/components/modals/SwitchAssetModal.vue";
import HistoryModal from "@/components/modals/HistoryModal.vue";
import OwnerIncidentsModal from "@/components/modals/OwnerIncidentsModal.vue";
import WasParkedModal from '@/components/modals/WasParkedModal.vue';
import Notes from "@/components/incidentdetail/Notes.vue";
import VueScrollTo from 'vue-scrollto';

export default {
    mixins: [setLocale],
    components: {
        Chat,
        MainBlock,
        MediaDocs,
        OtherDetails,
        Actions,
        Commission,
        Schedule,
        SwitchAssetModal,
        StartOwnerChatModal,
        HistoryModal,
        Notes,
        OwnerIncidentsModal,
        WasParkedModal
    },
    data() {
        return {
            profile: {},
            admin: false,
            hasMandates: false,
            selectedAsset: {},
            incidentId: 0,
            incidentInfo: {},
            owner: {},
            categories: [],
            loading: true,
            mapsApiUrl: '',
            mapsUrl: '',
            repairCompanies: [],
            mechanics: [],
            mechanicsNoS1mone: [],
            appointmentModalOpen: false,
            historyModalOpen: false,
            hasRepairCompany: false,
            preferredSuppliers: [],
            selectedRepairCompanyId: null,
            hasMechanic: false,
            selectedMechanic: null,
            tempAdditionalMechanic: '',
            manualCheckIn: false,
            manualCheckOut: false,
            manualCheckInOut: false,
            weekend: false,
            media: [],
            imagePresent: false,
            movs: [],
            mediaCount: 0,
            docs: [],
            orders: [],
            noStatusChange: false,
            newIncident: false,
            readyForTakeover: false,
            readOnly: false,
            forwardedReadOnly: false,
            readyToFinish: false,
            readyForRestart: false,
            repairInfo: {},
            feedbackTooltip: '',
            feedbackCount: 0,
            chatKey: 0,
            hideChat: false,
            buildingmanager: {},
            buildingmanagers: [],
            passedIncident: false,
            bmId: 0,
            commissionStart: false,
            invoiceParty: {},
            selectedInvoiceParty: {},
            preStepChoice: '',
            currentAppointment: '',
            currentAppointmentDate: '',
            showSchedule: false,
            switchAssetModalOpen: false,
            hideRestart: false,
            historyList: [],
            canOwnerChat: false,
            actualName: '',
            ownerIncidentsModalOpen: false,
            isForwarded: false,
            vvevest: false,
            startOwnerChatModalOpen: false,
            showStartUserChatButton: false,
            wasParkedModalOpen: false,
        }
    },
    computed: mapState(['vve', 'mapsKey']),
    created() {
        this.getUserRights();
        let queryString = window.location.search;
        this.incidentId = queryString.split('=')[1];
        this.incidentId = this.incidentId.replace('&openchat', '');
        this.incidentId = this.incidentId.replace('&opennote', '');
        document.title = 'S1MONE | ' + this.incidentId;
        let self = this;
        fetch('/api/v1/incident_category/bybuildingmanager')
        .then(response => { response.json().then(
            function(data) {
                self.categories = data.data;
                self.categories = uniqBy(self.categories, 'id_incident_category');
                for (var i = 0; i <self.categories.length; i++) {
                    let category = self.categories[i];
                    category.dutch_name = category.dutch_name.split('*')[0];
                    if (category.english_name) {
                        category.english_name = category.english_name.split('*')[0];
                    }
                    if (category.dutch_name.includes('-2') || category.dutch_name.includes('-3')|| category.dutch_name.includes('-4')) {
                        self.categories.splice(i, 1);
                    }
                }
                if (self.locale === 'nl') {
                    self.categories = sortBy(self.categories, 'dutch_name');
                }
                else {
                    self.categories = sortBy(self.categories, 'english_name');
                }
            });
        });
        this.getManagers();
        setTimeout(function () {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }.bind(this), 100)

        if (localStorage.getItem('S1-historylist')) {
            this.historyList = JSON.parse(localStorage.getItem('S1-historylist'));
            let lastVisit = moment().format('DD/MM/YYYY HH:mm:ss');
            let datum;
            if (this.historyList.length === 10) {
                datum = this.historyList.map(function(e) { return e.created; }).sort()[0];
            }
            for (var i = 0; i < this.historyList.length; i++) {
                let item = this.historyList[i];
                if (item.id === this.incidentId) {
                    this.historyList.splice(i, 1);
                }
                if (datum && datum === item.created) {
                    this.historyList.splice(i, 1);
                }
            }
            let item = {
                id: this.incidentId,
                created: new Date(),
                date: lastVisit
            }
            this.historyList.push(item);
            this.historyList = sortBy(this.historyList, 'created').reverse();
            localStorage.setItem('S1-historylist', JSON.stringify(this.historyList));
            this.$store.dispatch('updateHistoryList', this.historyList);
        }
        else {
            let lastVisit = moment().format('DD/MM/YYYY HH:mm:ss');
            let item = {
                id: this.incidentId,
                created: new Date(),
                date: lastVisit
            }
            this.historyList.push(item);
            this.historyList = sortBy(this.historyList, 'id');
            localStorage.setItem('S1-historylist', JSON.stringify(this.historyList));
            this.$store.dispatch('updateHistoryList', this.historyList);
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getUserRights() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(resp => {
                if (!resp.ok) {
                    console.log('Backend error occurred, redirecting to signin page.');
                    window.location = '/signin.html';
                    return; 
                }
                return resp.json();
            })
            .then(data => {
                self.buildingmanager = data;
                self.bmId = data.id_building_manager;
                self.admin = data.building_manager_admin;
                self.hasMandates = data.has_mandates;
                self.canOwnerChat = data.can_owner_chat;
                self.actualName = data.actual_name;
                self.getIncidentInfo();
            });
        },
        getIncidentInfo(scrollCalendar, refreshChat, hideCalendar) {
            document.querySelector('body').classList.remove('modal-open');
            this.readyForTakeover = false;
            this.invoiceError = false;
            this.manualCheckIn = false;
            this.manualCheckOut = false;
            this.manualCheckInOut = false;
            this.removeFileModalOpen = false;
            this.switchAssetModalOpen = false;
            this.repairInfo = {};
            this.currentAppointment = '';
            this.currentAppointmentDate = '';
            this.invoiceCity = '';
            this.newIncident = false;
            this.readOnly = false;
            let self = this;
            fetch('/api/v1/incidentcombined?id_incident=' + self.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    data.day = moment(data.created).locale('nl').format('dddd');
                    data.date = moment(data.created).locale('nl').format('D MMMM YYYY');
                    data.time = moment(data.created).locale('nl').format('LT');
                    let created = moment(data.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                    data.last_status_full =  moment(data.last_status_time).locale('nl').format('DD/MM/YYYY HH:mm:ss');
                    data.appointment_created_full =  moment(data.appointment_created).locale('nl').format('DD/MM/YYYY HH:mm:ss');
                    data.order_date_full = moment(data.order_date).locale('nl').format('DD/MM/YYYY HH:mm:ss');
                    let now = moment().format('DD/MM/YYYY HH:mm:ss');
                    let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                    let differenceObj = self.convertToDays(difference);
                    let differenceString = differenceObj.days + self.profile.datetime.days + ' ' + differenceObj.hours + self.profile.datetime.hours + ' ' +  differenceObj.minutes  + self.profile.datetime.minutes;
                    data.overtime = differenceString;
                    if (differenceObj.days < 1) {
                        data.overtimeStatus = 'green';
                    }
                    if (differenceObj.days > 1) {
                        data.overtimeStatus = 'orange';
                    }
                    if (differenceObj.days > 3) {
                        data.overtimeStatus = 'red';
                    }
                    if (data.start_time) {
                        var start = moment(data.start_time).format();
                        let startDay = moment(start).format('DD');
                        start = moment(data.start_time).locale('nl').format('D MMMM, H:mm');
                        var date = moment(data.start_time).locale('nl').format('D MMMM');
                        var end = moment(data.end_time).format();
                        let endDay = moment(end).format('DD');
                        let endDayDate = moment(end).locale('nl').format('D MMMM');
                        end = moment(data.end_time).format('H:mm');
                        self.currentAppointmentDate = date;
                        if (startDay === endDay) {
                            self.currentAppointment = start + ' - ' + end;
                        }
                        else {
                            self.currentAppointment = start + ' - ' + endDayDate + ' ' + end;
                        }
                    }
                    let gmapsAddress    = data.place_address.replace(/ /g , "+")+"+"+data.place_city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    if (data.id_repair_company) {
                        self.hasRepairCompany = true;
                    }
                    else {
                        self.hasRepairCompany = false;
                    }
                    data.incident_description = data.incident_description.replace(/\r?\n/g, '<br>');
                    self.selectedRepairCompany = data.id_repair_company;
                    if (self.selectedRepairCompany) {
                        self.commissionStart = true;
                    }
                    if (data.incidentCategory) {
                        self.selectedCategory = data.incidentCategory.id_incident_category;
                    }
                    if (data.place_floor_name === '0' && self.industry !== 'leasing') {
                        if (self.locale === 'nl') {
                            data.place_floor_name = 'Begane grond'
                        }
                        if (self.locale === 'en') {
                            data.place_floor_name = 'Ground floor'
                        }
                    }
                    if (data.place_floor_name === 'VVEVEST') {
                        self.vvevest = true;
                        if (self.locale === 'nl') {
                            data.place_floor_name = 'Niet van toepassing'
                        }
                        if (self.locale === 'en') {
                            data.place_floor_name = 'Not applicable'
                        }
                    }
                    
                    if ((data.original_id_building_manager && data.id_building_manager === self.bmId) && (data.incident_status === 'pending_buildingmanager' || data.incident_status === 'new_') && !data.serviced_by_bm_user_id) {
                        if (!data.incident_agreement_flow) {
                            data.incident_status = 'new_';
                            self.newIncident = true;  
                            self.readOnly = true;    
                       }    
                    }

                    if (data.autodispatch && !data.serviced_by_bm_user_id && data.incident_status === 'new_repaircompany') {
                        self.newIncident = true;   
                        self.readOnly = true;    
   
                    }

                    if (data.incident_status === 'returned_to_buildingmanager' && !data.serviced_by_bm_user_id && data.incident_agreement_flow) {
                        self.newIncident = true;      
                        self.readOnly = true;    
                    }

                    if (data.id_building_manager !== self.bmId) {
                       self.isForwarded = true;
                    }

                    //Action buttons handling
                    if (!data.serviced_by_bm_user_id) {
                        if (data.incident_status === 'pending_repaircompany' || data.incident_status === 'pending_buildingmanager' || data.incident_status === 'fixed_agreed_repaircompany' || data.incident_status === 'new_repaircompany' || data.incident_status === 'maintenance_checkin_mechanic' || data.incident_status === 'fixed_agreed_auto') {
                            self.noStatusChange = true;
                        }
                        if ((data.incident_status === 'new_' ||
                            data.incident_status === 'pending_buildingmanager' ||
                            data.incident_status === 'fixed_agreed_repaircompany' ||
                            data.incident_status === 'new_repaircompany' ||
                            data.incident_status === 'maintenance_checkin_mechanic' ||
                            data.incident_status === 'fixed_agreed_auto') &&
                            (data.incident_agreement_flow !== 'WAITING_ON_PERSON' &&
                            data.incident_agreement_flow !== 'WAITING_ON_PERSON_REMINDED') &&
                            !data.has_order && (data.original_id_building_manager === data.id_building_manager || !data.original_id_building_manager)) {
                            self.newIncident = true;
                            self.readOnly = true;    
                        }
                        if ((data.incident_status === 'fixed_agreed_repaircompany') &&
                            data.has_order && (data.original_id_building_manager === data.id_building_manager || !data.original_id_building_manager)) {
                            self.newIncident = true;
                            self.readOnly = true;
                            self.readOnly = true;    
                        }
                        if (data.incident_status === 'pending_repaircompany' && data.autodispatch && !data.serviced_by_bm_user_id) {
                            self.newIncident = true;
                            self.noStatusChange = true;
                            self.readOnly = true;    
                        }
                    }
                    if (data.incident_status !== 'new_' && data.incident_status !== 'closed') {
                        if (data.current_user_id === data.serviced_by_bm_user_id) {
                            self.readyToFinish = true;
                        }
                        else {
                            self.readyToFinish = false;
                        }
                        if (data.serviced_by_bm_user_id !== null && data.current_user_id !== data.serviced_by_bm_user_id && (data.original_id_building_manager ==  null || data.id_building_manager === data.original_id_building_manager)) {
                            self.readyForTakeover = true;
                            self.readOnly = true;
                        }
                    }
                    if (data.incident_status === 'closed' || data.incident_status === 'cancelled') {
                        self.readyForRestart = true;
                        self.readOnly = true;
                    }
                    else {
                        self.readyForRestart = false;
                    }
                    if (data.incident_agreement_flow === 'WAITING_ON_PERSON' || data.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED') {
                        self.readOnly = true;
                    }
                    if (data.id_building_manager !== self.bmId) {
                        self.forwardedReadOnly = true;
                    }
                    if (data.incident_status === 'fixed_mechanic' || data.incident_status === 'fixed_agreed_reporter' || data.incident_status === 'fixed_agreed_auto' || data.incident_status === 'fixed_agreed_repaircompany' || data.incident_status=== 'closed') {
                        fetch('/api/v1/incidentcombined/repairinfo?id_incident=' + data.id_incident)
                        .then(response => { response.json().then(
                            function(data) {
                                var start = moment(data.start).format();
                                start = moment(data.start).format('D/MM/YYYY HH:mm');
                                var end = moment(data.end).format();
                                end = moment(data.end).format('D/MM/YYYY HH:mm');
                                var timeWorked = self.createWorkedString(data.timeWorked);
                                data.start = start;
                                data.end = end;
                                data.timeWorked = timeWorked;
                                self.repairInfo = data;
                            });
                        });
                    }
                    if (data.pictures) {
                        self.media = [];
                        self.movs = [];
                        self.docs = [];
                        let invoices = [];
                        for (var i = 0; i < data.pictures.length; i++) {
                            let media = data.pictures[i];
                            if (self.locale === 'nl') {
                                media.date = moment(media.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                            }
                            else {
                                media.date = moment(media.created).format('MMM D YYYY HH:mm:ss');
                            }
                            if (media.content_type) {
                                if ((!media.file_url.includes('.pdf') && media.content_type.toLowerCase() !== 'application/pdf') && media.content_type !== 'video/quicktime') {
                                    media.popover = false;
                                    media.rotate = 1;
                                    if (media.type === 'incident_reporter') {
                                        media.order = 1;
                                    }
                                    if (media.type === 'incident_building_manager') {
                                        media.order = 2;
                                    }
                                    if (media.type === 'incident_mechanic') {
                                        media.order = 3;
                                    }
                                    media.selected = false;
                                    if (!media.content_type) {
                                        media.content_type = 'image/jpeg';
                                    }
                                    if (media.content_type === 'image/jpeg') {
                                        self.imagePresent = true;
                                    }
                                    self.media.push(media);
                                    self.media = sortBy(self.media, 'order');
                                }
                                if (media.file_url.includes('.pdf') || media.content_type.toLowerCase() === 'application/pdf') {
                                    let n = media.file_url.lastIndexOf('/');
                                    let fileName = media.file_url.substring(n + 1);
                                    media.pdf = true;
                                    media.filename = fileName;
                                    media.selected = false;
                                    if (media.type === 'order_request_supplier') {
                                        invoices.push(media);
                                    }
                                    if (media.type !== 'order_request_supplier' && media.type !== 'invoice_pdf_supplier') {
                                        self.docs.push(media);
                                    }
                                    if (media.type === 'quotation_pdf_supplier') {
                                        data.hasQuotation = true;
                                        data.quotationUrl = media.file_url;
                                    }
                                }
                                if (media.content_type === 'video/quicktime') {
                                    self.movs.push(media);
                                }
                            }       
                        }
                        if (invoices.length) {
                            self.orders = invoices;
                            let latestInvoice = invoices.reduce((max, invoice) => max.created > invoice.created ? max : invoice);
                            if (self.locale === 'nl') {
                                latestInvoice.description = latestInvoice.description.replace('invoice', 'opdrachtbon')
                            }
                            else {
                                latestInvoice.description = latestInvoice.description.replace('invoice', 'orderform')
                            }
                            self.docs.push(latestInvoice);
                        }
                    }
                    if (data.incident_status=='planned' || (data.calendar_choice=='choose_latest_possible' && data.incident_status=='pending_mechanic') || data.incident_status=='paused_mechanic' ) {
                        if (data.canbe_id_repair_company !== -1 || data.repair_company_only_email) {
                            self.manualCheckIn = true;
                        }
                    }
                    if (data.incident_status=='on_location_mechanic') {
                        if (data.canbe_id_repair_company !== -1 || data.repair_company_only_email) {
                            self.manualCheckOut = true;
                        }
                    }
                    if (data.incident_status === 'planned') {
                        if (data.canbe_id_repair_company !== -1 || data.repair_company_only_email) {
                            self.manualCheckInOut = true;
                        }
                    }
                    if (data.latest_fixed_agreed_repaircompany) {
                        data.latest_fixed_agreed_repaircompany_date = moment(data.latest_fixed_agreed_repaircompany).format('DD/MM/YYYY HH:mm:ss');
                    }
                    if (data.service_desk_suspended_until) {
                        data.service_desk_suspended_until_date = moment(data.service_desk_suspended_until).add(1, 'days').locale('nl').format('D MMMM YYYY');
                    }
                    if (data.original_id_building_manager && data.original_id_building_manager !== self.bmId) {
                        self.passedIncident = true;
                    }
                    if (data.incidentCategory && data.incidentCategory.dutch_name === 'KOPIE CONTRACT') {
                        self.hideChat = true;
                    }
                    self.selectedRepairCompanyId = data.id_repair_company;
                    if (data.canbe_id_repair_company === -1 || !data.canbe_id_repair_company) {
                        self.preStepChoice = 'other';
                    }
                    self.incidentInfo = data;
                    if (self.incidentInfo.reporter_phone.length < 4)  {
                        self.incidentInfo.reporter_phone = '';
                    }
                    if (!self.passedIncident) {
                        self.getAssets();
                    }
                    if (self.id_mechanic) {
                        self.selectedMechanic = self.incidentInfo.id_mechanic;
                    }
                    else {
                        self.selectedMechanic = 0;
                    }
                    if (self.incidentInfo.teams.length) {
                        self.incidentInfo.team_id_team = self.incidentInfo.teams[0].id_team;
                        self.incidentInfo.team_name = self.incidentInfo.teams[0].name;
                    }
                    if (!self.incidentInfo.original_id_building_manager || self.incidentInfo.original_id_building_manager === self.incidentInfo.id_building_manager) {
                        self.getOwner();
                    }
                    if (self.incidentInfo.quotation_value_supplier && self.incidentInfo.quotation_value_supplier.toString().includes('.')) {
                        self.incidentInfo.quotation_value_supplier = (Math.round(self.incidentInfo.quotation_value_supplier * 100) / 100).toFixed(2);
                        self.incidentInfo.quotation_value_supplier = self.incidentInfo.quotation_value_supplier.toString().replaceAll('.', ',');
                    }
                    if (self.incidentInfo.invoice_value_supplier) {
                        self.incidentInfo.invoice_value_supplier = (Math.round(self.incidentInfo.invoice_value_supplier * 100) / 100).toFixed(2);
                        self.incidentInfo.invoice_value_supplier = self.incidentInfo.invoice_value_supplier.toString().replaceAll('.', ',');
                    }
                    if (self.incidentInfo.autodispatch && self.incidentInfo.incident_status === 'fixed_agreed_repaircompany' && self.incidentInfo.current_user_id !== self.incidentInfo.serviced_by_bm_user_id) {
                        self.hideRestart = true;
                    }
                    if (self.incidentInfo.autodispatch && self.incidentInfo.incident_status === 'fixed_agreed_repaircompany' && self.incidentInfo.current_user_id === self.incidentInfo.serviced_by_bm_user_id) {
                        self.hideRestart = false;
                    }
                    if (self.incidentInfo.was_parked) {
                        self.wasParkedModalOpen = true;
                    }
                    self.getPreferredSuppliers();
                    self.checkContactPersons();
                    if (scrollCalendar) {
                        setTimeout(function () {
                            var options = {
                                
                                set: -60
                            }
                            let element = document.getElementById('calendar');
                            VueScrollTo.scrollTo(element, 200, options);
                        }.bind(this), 500);
                    }
                    if (hideCalendar) {
                        self.showSchedule = false;
                    }
                    if (refreshChat) {
                        setTimeout(function () {
                            self.$refs.chatRef.addWhatsappToChat();
                        }.bind(this), 500);
                    }
                    console.log(self.incidentInfo);
                    self.loading = false;
                });
            });
        },
        getPreferredSuppliers() {
            if (this.incidentInfo.incidentCategory && this.incidentInfo.id_owner && this.incidentInfo.incidentCategory.id_incident_category) {
                let self = this;
                fetch('/api/v1/ownerincidentcategoryrepaircompany/byidownerincidentcategory?id_owner=' + this.incidentInfo.id_owner + '&id_incident_category=' + this.incidentInfo.incidentCategory.id_incident_category)
                .then(response => { response.json().then(
                    function(data) {
                        if (data.length) {
                            self.preferredSuppliers = data;
                        }
                    });
                });
            }
        },
        getManagers() {
            let self = this;
            fetch('/api/v1/buildingmanagerbuildingmanager/bycurrentbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.buildingmanagers = data.data;
                    setTimeout(function () {
                        if (!self.incidentInfo.id_incident) {
                            window.location = '/buildingmanager';
                        }
                    }.bind(this), 8100);
                });
            });
        },
        checkContactPersons() {
            let self = this;
            fetch('/api/v1/contactperson/byidowner?id_owner=' + this.incidentInfo.id_owner)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        self.showStartUserChatButton = true;
                    }
                });
            });
        },
        getOwner() {
            if (this.incidentInfo.id_owner) {
                let self = this;
                fetch('/api/v1/owner/byid?id_owner=' + this.incidentInfo.id_owner)
                .then(response => { response.json().then(
                    function(data) {
                        self.owner = data;
                        self.selectedInvoiceParty = self.owner;
                        self.selectedInvoiceParty.invoice_name = self.incidentInfo.owner_name;
                        self.selectedInvoiceParty.invoice_default_text = self.buildingmanager.invoice_default_text;
                        self.selectedInvoiceParty.invoice_phone = self.selectedInvoiceParty.mobile;
                        if (!self.selectedInvoiceParty.invoice_email) {
                            if (!self.selectedInvoiceParty.invoice_address && !self.selectedInvoiceParty.invoice_house_number && !self.selectedInvoiceParty.invoice_zipcode && !self.selectedInvoiceParty.invoice_city) {
                                self.invoiceError = true;
                            }
                        }
                        if (!self.selectedInvoiceParty.invoice_address || !self.selectedInvoiceParty.invoice_house_number || !self.selectedInvoiceParty.invoice_zipcode || !self.selectedInvoiceParty.invoice_city) {
                            if (!self.selectedInvoiceParty.invoice_email) {
                                self.invoiceError = true;
                            }
                        }
                        self.$store.dispatch('updateOwnerLoaded');
                    });
                });
            }
            else {
                this.selectedInvoiceParty = this.buildingmanager;
                this.selectedInvoiceParty.invoice_name = this.buildingmanager.building_manager_name;
                this.selectedInvoiceParty.mobile = this.buildingmanager.building_manager_mobile;
                if (!self.selectedInvoiceParty.invoice_email) {
                    if (!self.selectedInvoiceParty.invoice_address && !self.selectedInvoiceParty.invoice_house_number && !self.selectedInvoiceParty.invoice_zipcode && !self.selectedInvoiceParty.invoice_city) {
                        self.invoiceError = true;
                    }
                }
                if (!self.selectedInvoiceParty.invoice_address || !self.selectedInvoiceParty.invoice_house_number || !self.selectedInvoiceParty.invoice_zipcode || !self.selectedInvoiceParty.invoice_city) {
                    if (!self.selectedInvoiceParty.invoice_email) {
                        self.invoiceError = true;
                    }
                }
            }
        },
        createWorkedString(timeWorked) {
            var result;
            var days;
            var hours;
            var minutes;
            let daysStr;
            let hoursStr;
            let minutesStr;
            if (!days) {
                daysStr   = this.profile.datetime.days;
                hoursStr  = this.profile.datetime.hours;
                minutesStr = this.profile.datetime.minutes;
            }

            days = Math.floor(timeWorked/86400000);
            days = days < 0 ? 0 : days;

            timeWorked = timeWorked - days*86400000;
            hours = Math.floor(timeWorked/3600000);
            hours = hours < 0 ? 0 : hours;

            timeWorked = timeWorked - hours*3600000;
            minutes = Math.floor(timeWorked/60000);
            minutes = minutes < 0 ? 0 : minutes;

            result = days+daysStr+" ";
            result += hours+hoursStr+" ";
            result += minutes+minutesStr;

            return result;
        },
        getAssets() {
            let self = this;
            fetch('/api/v1/asset/incident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    if (data.data) {
                        for (var i = 0; i < data.data.length; i++) {
                            let asset = data.data[i];
                            if (asset.id_asset === self.incidentInfo.id_asset) {
                                self.selectedAsset = asset;
                            }
                        }
                    }
                });
            });
        },
        convertToDays(milliSeconds){
            let days = Math.floor(milliSeconds/(86400 * 1000));
            milliSeconds -= days*(86400*1000);
            let hours = Math.floor(milliSeconds/(60 * 60 * 1000 ));
            milliSeconds -= hours * (60 * 60 * 1000);
            let minutes = Math.floor(milliSeconds/(60 * 1000));
            return {
                days, hours, minutes
            }
        },
        startCommissioning() {
            this.commissionStart = true;
            setTimeout(function () {
                var options = {
                    offset: -60
                }
                let element = document.getElementById('repblock');
                if (!element) {
                    element = document.getElementById('invoiceBlock');
                }
                VueScrollTo.scrollTo(element, 200, options);
            }.bind(this), 500);
        },
        restartCommissioning() {
            this.hideRestart = true;
            this.$store.dispatch('updateRecommission');
        },
        getMechanics(showCalendar) {
            let self = this;
            if (this.incidentInfo.has_order) {
                fetch('/api/v1/mechanic/byincident?id_incident=' + this.incidentInfo.id_incident)
                .then(response => { response.json().then(
                    function(data) {
                        self.mechanics = [];
                        self.mechanicsNoS1mone = [];
                        if (self.locale === 'nl') {
                            self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Niet van toepassing' };
                        }
                        else {
                            self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Not applicable' };
                        }
                        for (var i = 0; i < data.mechanics.length; i++) {
                            let mechanic = data.mechanics[i];
                            if (mechanic.only_email) {
                                self.mechanicsNoS1mone.push(mechanic);
                            }
                            else {
                                self.mechanics.push(mechanic);
                            }
                        }
                        if (showCalendar) {
                            setTimeout(function () {
                                var options = {
                                    offset: -60
                                }
                                let element = document.getElementById('calendar');
                                VueScrollTo.scrollTo(element, 200, options);
                            }.bind(this), 500);
                        }
                    });
                });
            }
            else {
                fetch('/api/v1/mechanic/byrepaircompany?id_repair_company=' + this.incidentInfo.canbe_id_repair_company)
                .then(response => { response.json().then(
                    function(data) {
                        self.mechanics = [];
                        self.mechanicsNoS1mone = [];
                        if (self.locale === 'nl') {
                            self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Niet van toepassing' };
                        }
                        else {
                            self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Not applicable' };
                        }                        
                        for (var i = 0; i < data.data.length; i++) {
                            let mechanic = data.data[i];   
                            if (mechanic.only_email) {
                                self.mechanicsNoS1mone.push(mechanic);
                            }
                            else {
                                self.mechanics.push(mechanic);
                            }
                        }
                    });
                });
            }
        },
        showCalendar(bool) {
            this.showSchedule = bool;
            setTimeout(function () {
                var options = {
                    offset: -60
                }
                let element = document.getElementById('calendar');
                VueScrollTo.scrollTo(element, 200, options);
            }.bind(this), 500);
        },
        showReadOnlyMsg() {
            if (this.readonly) {
                let readOnlyMsg = {
                    body: this.profile.toast.readonly_text,
                    title: this.profile.toast.readonly,
                    type: "warning",
                    theme: "light",
                    duration: 2000
                }
                this.$vToastify.info(readOnlyMsg);
            }
        },
        commissionRemoved() {
            this.commissionStart = false;
        },
        repairCompanySubmit() {
            this.$refs.chatRef.addWhatsappToChat();
        },
        handleNewStatus() {
            let status;
            if (this.noStatusChange) {
                status = this.incidentInfo.incident_status;
            }
            else {
                status = 'pending_buildingmanager';
            }
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('message', '');
            data.append('status', status);
            let self = this;
            fetch('/api/v1/incident/changestatus', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                if (!self.noStatusChange) {
                    self.incidentInfo.incident_status = 'pending_buildingmanager';
                }
                self.getIncidentInfo();
            });
        }
    }
}
</script>
