<template>
    <div>
        <div class="chat" :class="{active: chatOpen, enlarged: chatEnlarged}" v-if="profile.incidents && chatReady">
            <span class="chat-head" data-testid="incidentChatHead" @click="openChat()">
                <i class="fas fa-comments"></i> Chat
                <span class="newchats" data-testid="chatNewText" v-if="newChats"></span>
            </span>
            <span class="enlarge-chat" @click="chatEnlarged = !chatEnlarged">
                <i class="fas fa-expand-alt"></i>
            </span>
            <div class="chat-body">
                <div class="dialogs" v-if="dialogs.length > 1 && !dialogSelected">
                    <div class="dialog" v-for="(dialog, index) in dialogs" :key="index" @click="selectDialog(dialog)" v-show="!selectedDialog.nameShown">
                        <div class="row">
                            <div class="col">
                                <span class="unread-count" data-testid="chatUnreadText" v-if="dialog.unread"></span>
                                <span class="profile-name" v-if="dialog.reporterChat">
                                    <span class="chat-type head">{{profile.incident_detail.incident_reporter}}</span>
                                    <span class="name" data-testid="chatContactButton">
                                        <span class="initials">{{reporterInitials}}</span>
                                        <p>{{incidentInfo.reporter_name}}</p>
                                    </span>
                                </span>
                                <span class="profile-name" v-if="dialog.managerChat">
                                    <span class="chat-type head">{{profile.incidents.incidenttable.building_manager}}</span>
                                    <span class="name" data-testid="chatContactButton">
                                        <img :src="incidentInfo.building_manager_profile_image_loc" class="profile-pic" v-if="incidentInfo.building_manager_profile_image_loc" />
                                        <span class="initials" v-else>{{servicedeskInitials}}</span>
                                        <p>{{incidentInfo.building_manager_name}}</p>
                                    </span>
                                </span>
                                <div class="message-row" v-if="dialog.messages.length">
                                    <i class="fas fa-comments"></i>
                                    <p class="message" v-if="!dialog.lastMessage.body.includes('digitalocean') && !dialog.lastMessage.body.includes('twilio') || dialog.messageType" v-html="dialog.lastMessage.body"></p>
                                    <img :src="dialog.lastMessage.body" class="message-image" v-if="dialog.messageType && (dialog.lastMessage.body.includes('digitalocean') || dialog.lastMessage.body.includes('twilio')) && dialog.messageType === 'image'" />
                                    <video v-if="dialog.messageType && (dialog.lastMessage.body.includes('digitalocean') || dialog.lastMessage.body.includes('twilio')) && dialog.messageType === 'video'" controls><source :src="dialog.lastMessage.body" type="video/mp4"></video>
                                </div>
                                <span class="timedate" v-if="dialog.messages.length">{{dialog.lastMessage.time}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="selected-dialog" v-if="dialogSelected" :class="{single: singleDialog}">
                    <div class="dialog-head">
                        <span class="back-link" @click="clearDialog()" v-if="!singleDialog && !backDisabled"><i class="fas fa-arrow-left"></i></span>
                        <span class="profile-name" v-if="selectedDialog.reporterChat">
                            <span class="chat-type head">{{profile.incident_detail.incident_reporter}}</span>
                            <span class="name" data-testid="chatContactButton">
                                <span class="initials">{{reporterInitials}}</span>
                                <p>{{incidentInfo.reporter_name}}</p>
                            </span>
                        </span>
                        <span class="profile-name" v-if="selectedDialog.managerChat">
                            <span class="chat-type head">{{profile.incidents.incidenttable.building_manager}}</span>
                            <span class="name" data-testid="chatContactButton">
                                <img :src="incidentInfo.building_manager_profile_image_loc" class="profile-pic" v-if="incidentInfo.building_manager_profile_image_loc" />
                                <span class="initials" v-else>{{servicedeskInitials}}</span>
                                <p>{{incidentInfo.building_manager_name}}</p>
                            </span>
                        </span>
                    </div>
                    <div class="messages" id="messages">
                        <div class="message" v-for="(message, index) in selectedDialog.messages" :key="index" :class="{received: message.received, sent: message.sent, undelivered: message.status === 'whatsapp_undelivered', mediaText: message.mediaText !== '' && message.mediaText !== ' ' && message.mediaText !== 'null '}" v-show="message.body">
                            <img :src="message.body" class="image" data-testid="chatImageMessage" v-if="message.mediaContentType && message.mediaContentType.includes('image/')" />
                            <video data-testid="chatVideoMessage" v-if="message.mediaContentType && message.mediaContentType.includes('video')" controls><source :src="message.body" type="video/mp4"></video>
                            <p data-testid="chatPdfMessage" v-if="message.isMedia && message.body.includes('.pdf')"><a :href="message.body" style="color: white; text-decoration: none;" target="blank"><i class="fas fa-file-pdf" style="font-size: 24px; margin-right: 5px;"></i> {{message.docName}}</a></p>
                            <p data-testid="chatTextMessage" v-if="message.mediaText !== '' && message.mediaText !== ' ' && message.mediaText !== undefined && !message.mediaText.includes('null')" class="media-text">{{message.mediaText}}</p>
                            <p data-testid="chatMessage" v-if="!message.isMedia" v-html="message.body"></p>
                            <span data-testid="chatStatusMessage"><b v-if="message.status === 'whatsapp_undelivered'">{{profile.incident_detail.whatsapp_undelivered}} | </b>{{message.time}}</span>
                        </div>
                    </div>
                    <form v-on:submit.prevent="sendMessage()" class="new-message">
                        <div style="float: left; width: 100%; position: relative;">
                            <span class="add-attachment" data-testid="chatFileButton" @click="mediaModalOpen = true" v-tooltip.right="profile.accountPage.account.add_files"><i class="fas fa-paperclip"></i></span>
                            <textarea class="form-control" data-testid="chatTextInput" rows="2" placeholder="Verstuur een bericht..." @input="e => newMessage = e.target.value" :value="newMessage" @click="scrollToBottom()" @keydown="sendMessage($event)" id="textinput" maxlength="1500" ref="messageInput"></textarea>
                            <button type="submit"><i class="fas fa-paper-plane" data-testid="chatSendButton"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <MediaModal v-if="mediaModalOpen" @close="getIncidentInfo()" @success="getMessagesManager()" @sendMessage="sendMessage" :incidentId="incidentId" :files="incidentInfo.pictures" :selectedDialog="selectedDialog" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { websocketMechanic } from '@/mixins/websocket-mechanic.js';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import MediaModal from "@/components/modals/MediaModal.vue";

export default {
    mixins: [setLocale, websocketMechanic],
    components: {
        MediaModal
    },
    name: 'Chat',
    props: ['incidentId', 'incidentInfo'],
    data() {
        return {
            profile: {},
            dialogs: [],
            chatReady: false,
            selectedDialog: {},
            chatOpen: false,
            newChats: false,
            newMessage: '',
            chatEnlarged: false,
            mediaModalOpen: false,
            unread: false,
            singleDialog: false,
            dialogSelected: false,
            reporterInitials: '',
            servicedeskInitials: '',
            monthsNumbered: {
        	  "1" : "Jan",
        	  "2" : "Feb",
        	  "3" : "Maa",
        	  "4" : "Apr",
        	  "5" : "Mei",
        	  "6" : "Jun",
        	  "7" : "Jul",
        	  "8" : "Aug",
        	  "9" : "Sep",
        	  "10" : "Okt",
        	  "11" : "Nov",
        	  "12" : "Dec"
          },
          backDisabled: false
        }
    },
    computed: mapState(['fileRemoved', 'fileAdded']),
    watch: {
        fileRemoved() {
            if (this.fileRemoved) {
                let message = '';
                if (this.locale === 'nl') {
                    message = 'Er is een beeld / bestand uit de melding verwijderd.'
                }
                else {
                    message = 'A file / image has been removed from the incident.'
                }
                this.sendMessage(message, true);
            }  
            else {
                setTimeout(function () {
                    this.setwhatsappasread('servicedesk');
                    this.newChats = false;
                    this.dialogs[0].unread = 0;
                    this.dialogs[1].unread = 0;
                }.bind(this), 3000);
            }  
        },
        fileAdded() {
            if (this.fileAdded) {
                let message = '';
                if (this.locale === 'nl') {
                    message = 'Er is een beeld / bestand toegevoegd aan de melding.'
                }
                else {
                    message = 'A file / image has been added to the incident.'
                }
                this.sendMessage(message, true);
            }   
            else {
                setTimeout(function () {
                    this.setwhatsappasread('servicedesk');
                    this.newChats = false;
                    this.dialogs[0].unread = 0;
                    this.dialogs[1].unread = 0;
                }.bind(this), 3000);
            }
        }
    },
    created() {
        setTimeout(function () {
            if (this.incidentInfo.canbe_id_repair_company === this.incidentInfo.id_repair_company) {
                this.getMessagesManager();
            }
            else {
                if (this.incidentInfo.whatsapp) {
                    this.getMessagesReporter();
                }
            }
        }.bind(this), 500);
    },
    methods: {
        getIncidentInfo() {
            this.mediaModalOpen = false;
            this.$emit('getIncidentInfo');
        },
        openChat() {
            this.chatOpen = !this.chatOpen;
            if (this.dialogs.length === 1) {
                this.newChats = false;
                if (this.dialogs[0].managerChat) {
                    this.setwhatsappasread('servicedesk');
                    this.dialogs[0].unread = false;
                }
                else {
                    this.setwhatsappasread('reporter');
                    this.dialogs[0].unread = false;
                }
            }
            else {
                this.selectedDialog = {};
                this.dialogSelected = false;
            }
        },
        scrollToBottom() {
            let element = document.getElementById('messages');
            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        },
        selectDialog(dialog) {
            this.selectedDialog = dialog;
            this.dialogSelected = true;
            if (this.chatOpen) {
                if (dialog.managerChat) {
                    this.setwhatsappasread('servicedesk');
                    dialog.unread = false;
                }
                else {
                    this.setwhatsappasread('reporter');
                    dialog.unread = false;
                }
            }
            else {
                if (dialog.unread) {
                    this.newChats = true;
                }
            }
            setTimeout(function () {
                let element = document.getElementById('messages');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                    document.getElementById('textinput').focus();
                }
            }.bind(this), 500);
        },
        getMessagesManager() {
            this.dialogs = [];
            if (this.incidentInfo.canbe_id_repair_company === this.incidentInfo.id_repair_company) {
                let self = this;
                fetch('/api/v1/chat/byincident?id_incident=' + this.incidentId + '&limit=100&type=servicedesk_mechanic')
                .then(response => { response.json().then(
                    function(data) {
                        let serviceDeskDialog = {
                            nameShown: self.incidentInfo.reporter_name,
                            managerChat: true,
                            unread: data.unread_count,
                            messages: []
                        }
                        for (var i = 0; i < data.messages.length; i++) {
                            let dateStr = '';
                            let received = false;
                            let sent = false;
                            let mediaText = '';
                            if (data.messages[i].dateSentMillis) {
                                let dateSent = new Date(data.messages[i].dateSentMillis);
                                let month = dateSent.getMonth() + 1;
                                month = month.toString();
                                dateStr = dateSent.getDate() + " " + self.monthsNumbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            }
                            if (!data.messages[i].to.includes(self.incidentInfo.id_building_manager)) {
                                received = true;
                            }
                            else {
                                sent = true;
                            }
                            if (data.messages[i].isMedia) {
                                mediaText = data.messages[i].body.split('https')[0];
                                data.messages[i].body = 'https' + data.messages[i].body.split('https')[1];
                            }
                            else {
                                data.messages[i].body = data.messages[i].body.replaceAll(/\r?\n/g, '<br>');
                            }
                            let message = {
                                body: data.messages[i].body,
                                from: data.messages[i].from,
                                time: dateStr,
                                received: received,
                                sent: sent,
                                isMedia: data.messages[i].isMedia,
                                mediaText: mediaText,
                                docName: '',
                                status: data.messages[i].status,
                                timestamp: data.messages[i].dateSentMillis,
                                isBMNotification: data.messages[i].isBMNotification,
                                mediaContentType: data.messages[i].mediaContentType
                            }
                            if (data.messages[i].isMedia && !data.messages[i].mediaContentType) {
                                message.mediaContentType = 'image/jpeg';
                            }
                            if (message.body.includes('.pdf')) {
                                let n = message.body.lastIndexOf('/');
                                let fileName = message.body.substring(n + 1);
                                message.docName = fileName;
                            }
                            if (!message.isBMNotification && message.status !== 'resend') {
                                serviceDeskDialog.messages.push(message);
                            }
                            serviceDeskDialog.messages = uniqBy(serviceDeskDialog.messages, 'timestamp');
                            serviceDeskDialog.messages = sortBy(serviceDeskDialog.messages, 'timestamp');
                            serviceDeskDialog.lastMessage = serviceDeskDialog.messages.slice(-1).pop();
                        }
                        self.dialogs.push(serviceDeskDialog);
                        if (self.incidentInfo.whatsapp) {
                            self.getMessagesReporter();
                        }
                        else {
                            self.selectedDialog = serviceDeskDialog;
                            self.dialogSelected = true;
                            self.singleDialog = true;
                            self.chatReady = true;
                            if (self.selectedDialog.unread) {
                                self.newChats = true;
                            }
                            if (self.selectedDialog.messages) {
                                setTimeout(function () {
                                    let element = document.getElementById('messages');
                                    if (element) {
                                        element.scrollTop = element.scrollHeight;
                                    }
                                }.bind(self), 200);
                            }
                        }
                        if (self.selectedDialog.managerChat) {
                            self.selectedDialog = serviceDeskDialog;
                        }
                    });
                });
            }
            else {
                if (this.incidentInfo.whatsapp) {
                    this.getMessagesReporter();
                }
            }
        },
        getMessagesReporter() {
            this.mediaModalOpen = false;
            let self = this;
            fetch('/api/v1/chat/byincident?id_incident=' + this.incidentId + '&limit=100&type=reporter')
	        .then(response => { response.json().then(
	            function(data) {
                    let reporterDialog = {
                        nameShown: self.incidentInfo.reporter_name,
                        reporterChat: true,
                        unread: data.unread_count,
                        type: 'reporterChat',
                        messages: []
                    }
                    for (var i = 0; i < data.messages.length; i++) {
                        let dateStr = '';
                        let received = false;
                        let sent = false;
                        let mediaText = '';
                        if (data.messages[i].dateSentMillis) {
                            let dateSent = new Date(data.messages[i].dateSentMillis);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            dateStr = dateSent.getDate() + " " + self.monthsNumbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                        }
                        if (!data.messages[i].body.includes(self.incidentInfo.mechanic_name)) {
                            received = true;
                        }
                        else {
                            sent = true;
                        }
                        if (data.messages[i].isMedia) {
                            mediaText = data.messages[i].body.split('https')[0];
                            data.messages[i].body = 'https' + data.messages[i].body.split('https')[1];
                        }
                        let message = {
                            body: data.messages[i].body,
                            from: data.messages[i].from,
                            time: dateStr,
                            received: received,
                            sent: sent,
                            isMedia: data.messages[i].isMedia,
                            mediaText: mediaText,
                            docName: '',
                            status: data.messages[i].status,
                            timestamp: data.messages[i].dateSentMillis,
                            isBMNotification: data.messages[i].isBMNotification,
                            mediaContentType: data.messages[i].mediaContentType
                        }
                        if (data.messages[i].isMedia && !data.messages[i].mediaContentType) {
                            message.mediaContentType = 'image/jpeg';
                        }
                        else {
                            data.messages[i].body = data.messages[i].body.replaceAll(/\r?\n/g, '<br>');
                        }
                        if (message.body.includes('.pdf')) {
                            let n = message.body.lastIndexOf('/');
                            let fileName = message.body.substring(n + 1);
                            message.docName = fileName;
                        }
                        if (!message.isBMNotification && message.status !== 'resend') {
                            reporterDialog.messages.push(message);
                        }
                        reporterDialog.messages = uniqBy(reporterDialog.messages, 'timestamp');
                        reporterDialog.messages = sortBy(reporterDialog.messages, 'timestamp');
                        reporterDialog.lastMessage = reporterDialog.messages.slice(-1).pop();
                    }
                    self.dialogs.push(reporterDialog);
                    self.dialogs = sortBy(self.dialogs, 'reporterChat');
                    if (self.selectedDialog.reporterChat) {
                        self.selectedDialog = reporterDialog;
                    }
                    else {
                        for (var i = 0; i < self.dialogs.length; i++) {
                            let dialog = self.dialogs[i];
                            if (dialog.unread) {
                                self.newChats = true;
                            }
                        }
                    }
                    if (self.dialogs.length === 1) {
                        self.selectedDialog = self.dialogs[0];
                        self.dialogSelected = true;
                        self.singleDialog = true;
                    }
                    if (self.dialogs.length > 1 && !self.selectedDialog) {
                        self.dialogSelected = false;
                    }
                    setTimeout(function () {
                        if (self.selectedDialog.messages) {
                            let element = document.getElementById('messages');
                            if (element) {
                                element.scrollTop = element.scrollHeight;
                            }
                        }
                        self.reporterInitials = self.incidentInfo.reporter_name.split(" ").map((n)=>n[0]).join("");
                        self.chatReady = true;
                    }.bind(self), 200);
				});
	        });
        },
        pad(n) {
            return n < 10 ? '0' + n : n
        },
        sendMessage($event, managerChat) {
            if (typeof $event === 'string' && !managerChat) {
                this.getIncidentInfo();
            }
            document.querySelector('body').classList.remove('modal-open');
            this.mediaModalOpen = false;
            let textarea = this.$refs.messageInput;
            if (!this.selectedDialog.messages) {
                this.selectedDialog.messages = [];
            }
            if (typeof $event === 'string') {
                this.newMessage = $event;
            }
            if ($event) {
                if ($event.keyCode === 13 && !this.newMessage.length) {      
                    if (textarea) {
                        textarea.blur();
                    }     
                    setTimeout(function () {
                        textarea.focus();
                    }.bind(this), 200);                
                }
                if (($event.keyCode === 13 && this.newMessage.length || typeof $event === 'string') && !$event.shiftKey) {
                    this.handleSend(managerChat);
                }         
            }
            else {
                if (this.newMessage.length) {
                    this.handleSend();
                }
            }
        },
        handleSend(managerChat) {
            let textarea = this.$refs.messageInput;
            this.backDisabled = true;                         
            if (this.selectedDialog.managerChat || managerChat) {
                this.sendToServer("sendmessage_mechanic_to_servicedesk", this.incidentInfo.mechanic_name + ": " + this.newMessage, this.incidentId);
            }
            else {
                this.sendToServer("sendmessage", this.incidentInfo.mechanic_name + ": " + this.newMessage, this.incidentId);
            }
            this.newMessage = '';
            this.$store.dispatch('updateFileAdded', false);
            this.$store.dispatch('updateFileRemoved', false);
            if (textarea) {
                textarea.blur();
            }
            setTimeout(function () {
                textarea.focus();
            }.bind(this), 200);    
            setTimeout(function () {
                let element = document.getElementById('messages');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }.bind(this), 500);
        },
        setwhatsappasread(type) {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('type', type);
            let self = this;
            fetch('/api/v1/chat/setwhatsappasread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.newChats = false;
                for (var i = 0; i < self.dialogs.length; i++) {
                    let dialog = self.dialogs[i];
                    if (dialog.unread) {
                        self.newChats = true;
                    }
                }
            });
        },
        clearDialog() {
            this.dialogSelected = false;
            let self = this;
            fetch('/api/v1/messages/byincident?type=reporter&id_incident='+this.incidentId+'&limit='+1, {
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                for (var j = 0; j < data.length; j++) {
                    let message = data[j];
                    let createdEpoch = self.incidentInfo.created;
                    for (var i = 0; i < self.dialogs.length; i++) {
                        let dialog = self.dialogs[i];
                        if (dialog.whatsapp) {
                            let dateStr = '';
                            if (message.dateSentMillis>=(createdEpoch-10000)) {
                                let dateSent = new Date(data[0].dateSentMillis);
                                let month = dateSent.getMonth() + 1;
                                month = month.toString();
                                dateStr = dateSent.getDate() + " " + self.monthsNumbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            }
                            dialog.time = dateStr;
                            dialog.from = message.from;
                            dialog.message = message.body;
                        }
                    }
                }
            });
            if (this.selectedDialog.managerChat) {
                fetch('/api/v1/messages/byincident?type=servicedesk_mechanic&id_incident='+this.incidentId+'&limit='+1, {
                }).then((resp) => {
                    return resp.json();
                }).then(function(data) {
                    for (var j = 0; j < data.length; j++) {
                        let message = data[j];
                        let createdEpoch = self.incidentInfo.created;
                        for (var i = 0; i < self.dialogs.length; i++) {
                            let dialog = self.dialogs[i];
                            if (dialog.managerChat) {
                                let dateStr = '';
                                if (message.dateSentMillis>=(createdEpoch-10000)) {
                                    let dateSent = new Date(data[0].dateSentMillis);
                                    let month = dateSent.getMonth() + 1;
                                    month = month.toString();
                                    dateStr = dateSent.getDate() + " " + self.monthsNumbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                                }
                                dialog.time = dateStr;
                                dialog.from = message.from;
                                dialog.message = message.body;
                            }
                        }
                    }
                });
            }
            this.selectedDialog = { };
        }
    }
}
</script>
