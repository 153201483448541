<template>
    <div>
        <div class="main-content" v-if="profile.dashboard">
            <div class="container" style="padding-top: 20px;">
                <div class="row">
                    <div class="col">
                        <div class="searchterms" v-if="searchTerms.length">
                            <h4><i class="fa-solid fa-magnifying-glass-arrows-rotate"></i> {{profile.dashboard.building_manager.past_searches}}</h4>
                            <div v-for="(item, index) in searchTerms" :key="index" class="term-wrapper">
                                <span class="searchterm" @click="searchSuggest(item)">{{item}}</span>
                                <i class="fa fa-times" @click="removeTerm(item)"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="actionRequiredItems.length || currentIncidentItems.length || technicalReadyItems.length || closedIncidentItems.length">
                    <div class="col">
                        <section class="search dashboard" style="margin-bottom: 20px;" :class="{active: searchSuggestions.length}">
                            <input data-testid="dashboardSearchInput" type="text" v-bind:placeholder="profile.dashboard.building_manager.search_rep" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="searchSuggest($event)" />
                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                            <h4 class="result-counter" v-if="totalSuggestions === 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                            <h4 class="result-counter" v-if="totalSuggestions > 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                            <ul class="search-suggestions" v-if="searchSuggestions.length">
                                <h4 class="result-counter" v-if="totalSuggestions === 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="totalSuggestions > 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                <li v-for="(suggestion, index) in searchSuggestions" :key="index" @click="toIncident(suggestion.id_incident)" v-tooltip.center="suggestion.incident_description">
                                    <div class="indicators">
                                        <span data-testid="dashboardSearchNumber" class="number">{{suggestion.id_incident}}</span>
                                        <span data-testid="dashboardSearchAuto" class="auto-forward" v-if="suggestion.id_ori_incident">auto <i class="fas fa-forward"></i></span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_PERSON' || suggestion.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                        <span data-testid="dashboardSearchStatus" class="status" v-if="!profile['statusses'][suggestion.status].repaircompany">
                                            {{profile['statusses'][suggestion.status]}}
                                            <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                        </span>
                                        <span data-testid="dashboardSearchStatus2" class="status" v-if="profile['statusses'][suggestion.status].repaircompany">
                                            {{profile['statusses'][suggestion.status].repaircompany}}
                                            <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                        </span>
                                        <span data-testid="dashboardSearchSince" class="date">{{profile.incidents.incidenttable.since}}{{suggestion.overtime}}</span>
                                    </div>
                                    <span data-testid="dashboardSearchCategory" class="category" v-if="locale === 'nl'">{{suggestion.category_dutch_name}}</span>
                                    <span data-testid="dashboardSearchCategoryEn" class="category" v-else>{{suggestion.category_english_name}}</span>
                                    <span data-testid="dashboardSearchDate" class="date" style="clear: both; display: block; margin-left: 0;">{{profile.incidents.incidenttable.datetime}}: {{suggestion.createdDate}}</span>
                                    <p data-testid="dashboardSearchAddress">{{suggestion.address}} {{suggestion.house_number}}</p>
                                    <span data-testid="dashboardSearchAsset" class="asset">{{suggestion.asset}}</span>
                                    <span data-testid="dashboardSearchCompany" class="repaircompany" v-if="suggestion.repair_company_name" style="display: block; font-weight: 500; clear:both; margin-top: 3px;"><i class="fas fa-tools"></i> {{suggestion.repair_company_name}}</span>
                                    <span data-testid="dashboardSearchParked" class="parked" v-if="suggestion.suspended"><i class="fas fa-parking"></i> {{profile.incident_detail.parked}}</span>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>

                  <!-- Filters -->
                  <div class="dashboard-filters d-sm-block d-md-block d-lg-block">
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
                        <div class="col" v-if="settings.filters && buildingmanagerList.length">
                            <div class="dropdown-section">
                                <span data-testid="dashboardFilterRemove" v-if="settings.filters.buildingmanager" class="remove-selection" style="top: 15px; cursor: pointer; z-index: 2;" @click="removeBm()"><i class="fas fa-times"></i></span>
                                <span data-testid="dashboardFilterManagerDropdown" class="dropdown" :class="{active: buildingManagerDropdownToggled}" @click="buildingManagerDropdownToggled = !buildingManagerDropdownToggled">
                                    <span data-testid="dashboardFilterIncident" v-if="!settings.filters.buildingmanager" class="placeholder">{{profile.incidents.incidenttable.client}}</span>
                                    <span data-testid="dashboardFilterManagerName" v-if="settings.filters.buildingmanager" class="selected">{{settings.filters.buildingmanagerName}}</span>
                                    <span data-testid="dashboardFilterManager" v-if="settings.filters.buildingmanager" class="seperator">|</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="buildingManagerDropdownToggled">
                                    <ul>
                                        <li data-testid="DashboardFilterManagerList" v-for="bm in buildingmanagerList" v-on:click="selectBm(bm)" :key="bm.id_buildingmanager">{{bm.name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row visible-xs">
                    <div class="col">
                        <div class="button-group dashboard">
                            <span class="switch-button" :class="{active: showType}" @click="showType = true">{{profile.dashboard.building_manager.required_actions}}</span>
                            <span class="switch-button" style="margin-left: -1px;" :class="{active: showType == false}" @click="showType = false">{{profile.dashboard.building_manager.current_incidents}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="block" v-if="!actionRequiredItems.length && !currentIncidentItems.length && !technicalReadyItems.length && !actionrequiredLoading && !currentIncidentsLoading && !technicalReadyLoading" style="float: left;">
                            <h3 style="margin-bottom: 0;">{{profile.dashboard.building_manager.no_incidents_current}}</h3>
                        </div>
                    </div>
                </div> 
                <!-- Action Required Items -->
                <div class="items-wrap" :class="{load: actionrequiredLoading}">
                    <div class="row" :class="{hiddenxs: showType}">
                        <div class="col">
                            <div class="action-head">
                                <h3 data-testid="dashboardActionCount" class="action-indicator" v-if="actionRequiredItems.length || actionrequiredLoading">{{profile.dashboard.building_manager.required_actions}} <span v-if="actionRequiredCount">({{actionRequiredCount}})</span></h3>
                                <div class="view-switch" v-if="actionRequiredCount">
                                    <span class="table-switch" @click="switchView('actionrequired', 'table')" :class="{active: actionRequiredView === 'table'}" v-tooltip.right="profile.dashboard.table_view"><i class="fa-light fa-table"></i></span>
                                    <span class="grid-switch" @click="switchView('actionrequired', 'grid')" :class="{active: actionRequiredView === 'grid'}"  v-tooltip.right="profile.dashboard.list_view"><i class="fa-regular fa-grid"></i></span>
                                </div>
                                <div class="pagination" v-if="actionRequiredCount > 9" :class="{nomargin: actionRequiredCount < 9}">
                                    <span class="prev" v-if="actionRequiredCurrentPage !== 1" @click="prevPage('actionrequired')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="actionRequiredCurrentPage !== 1" @click="firstPage('actionrequired')">1</span>
                                    <span class="page-indicator">{{actionRequiredCurrentPage}} / {{actionRequiredTotalPages}}</span>
                                    <span class="page page--last" v-if="actionRequiredTotalPages > 2" @click="lastPage('actionrequired')">{{actionRequiredTotalPages}}</span>
                                    <span class="next" v-if="actionRequiredCurrentPage !== actionRequiredTotalPages" @click="nextPage('actionrequired')"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="@/assets/icons/loading.gif" v-if="actionrequiredLoading" class="loader" />
                    <div class="row" v-show="actionRequiredView === 'grid'" :class="{hiddenxs: showType}" v-if="actionRequiredCount && !actionrequiredLoading">
                        <div class="col">
                            <span class="sort"><i class="fa-regular fa-arrow-up-arrow-down"></i> {{profile.dashboard.building_manager.sort}}</span>
                            <div class="filters">
                                <span data-testid="dashboardActionSortId" class="filter hidden-xs" @click="sortTable('actionrequired', 'id_incident')" :class="{active: actionRequiredSortType === 'id_incident', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.invoicing.job}} <i class="fa-light fa-arrow-down"></i></span>
                                <span data-testid="dashboardActionSortAddress" class="filter hidden-xs" @click="sortTable('actionrequired', 'address')" :class="{active: actionRequiredSortType === 'address', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.address}} <i class="fa-light fa-arrow-down"></i></span>
                                <span data-testid="dashboardActionSortAsset" class="filter hidden-xs" @click="sortTable('actionrequired', 'asset')" :class="{active: actionRequiredSortType === 'asset', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.asset}} <i class="fa-light fa-arrow-down"></i></span>
                                <span data-testid="dashboardActionSortStatus" class="filter" @click="sortTable('actionrequired', 'status')" :class="{active: actionRequiredSortType === 'status', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.status}} <i class="fa-light fa-arrow-down"></i></span>
                                <span data-testid="dashboardActionSortAssigned" class="filter hidden-xs" @click="sortTable('actionrequired', 'assigned')" :class="{active: actionRequiredSortType === 'assigned', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.assigned}} <i class="fa-light fa-arrow-down"></i></span>
                                <span data-testid="dashboardActionSortCreated" class="filter hidden-xs" @click="sortTable('actionrequired', 'created')" :class="{active: actionRequiredSortType === 'created', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.datetime}} <i class="fa-light fa-arrow-down"></i></span>
                                <span data-testid="dashboardActionSortSince" class="filter" @click="sortTable('actionrequired', 'last_status_time')" :class="{active: actionRequiredSortType === 'last_status_time', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_in_status}} <i class="fa-light fa-arrow-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-view" v-show="actionRequiredView === 'grid'" :class="{hiddenxs: showType}" v-if="actionRequiredCount && !actionrequiredLoading">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4" v-for="(item, index) in actionRequiredItems" :key="index" v-tooltip.right="item.description" @click="toIncident(item.id_incident)">
                                <div class="incident-wrap">
                                    <div class="block block--action">
                                        <div class="block-head">
                                            <span data-testid="dashboardActionStatus" class="action-status" v-if="!profile['statusses'][item.status].repaircompany">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status]}}
                                            </span>
                                            <span data-testid="dashboardActionStatus2" class="action-status" v-if="profile['statusses'][item.status].repaircompany">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status].repaircompany}}
                                            </span>
                                            <span data-testid="dashboardActionId" class="number">
                                                <i class="fas fa-share" style="font-size: 14px;" v-if="item.passedIncident" v-tooltip.right="profile.incident_detail.passed_tooltip"></i>
                                                <i class="fas fa-paper-plane" style="font-size: 14px;" v-if="item.passedToIncident" v-tooltip.right="profile.incident_detail.passed_to_tooltip_to + ' ' + item.building_manager"></i>
                                                {{ item.id_incident}}
                                            </span>
                                        </div>
                                        <div class="block-content">
                                            <span class="post-it urgent" data-testid="supplierDashboardUrgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fa-regular fa-circle-exclamation"></i></span>
                                            <span class="post-it chatcount" v-if="item.chat_count" data-testid="chatCountLabel" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>  
                                            <div class="labels">
                                                <span data-testid="dashboardActionUser" v-if="item.rep_service_by_id_shiro_user_name" class="current-user" v-tooltip.right="profile.incident_detail.incident_who + ' ' + item.rep_service_by_id_shiro_user_name"><i class="fas fa-user"></i> {{item.rep_service_by_id_shiro_user_name}}</span>
                                                <span data-testid="dashboardActionAuto" class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                <span data-testid="dashboardActionAuto2" class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                            </div>
                                            <h3 data-testid="dashboardActionManager" style="font-size: 15px; margin-bottom: 5px;">{{item.building_manager}}</h3>
                                            <h4 data-testid="dashboardActionAddress">{{item.address}}</h4>
                                            <h5 data-testid="dashboardActionAsset">{{item.asset}}</h5>
                                            <h6 data-testid="dashboardActionSpecialist" v-if="item.repair_company"><i class="fas fa-tools"></i> {{item.repair_company}} <span v-if="item.mechanic">| {{item.mechanic}}</span></h6>
                                            <h6 data-testid="dashboardActionManager2" v-if="item.id_building_manager !== item.original_id_building_manager && !item.repair_company"><i class="fas fa-tools"></i> {{item.building_manager}}</h6>
                                            <span data-testid="dashboardActionSince" class="time-indicator" :class="item.overtimeStatus">{{item.overtime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-view" v-show="actionRequiredView === 'table'" v-if="actionRequiredItems.length">
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('actionrequired', 'id_incident')" :class="{active: actionRequiredSortType === 'id_incident'}">{{profile.invoicing.job}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'address')" :class="{active: actionRequiredSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'asset')" :class="{active: actionRequiredSortType === 'asset'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'status')" :class="{active: actionRequiredSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'rep_service_by_id_shiro_user_name')" :class="{active: actionRequiredSortType === 'rep_service_by_id_shiro_user_name'}">{{profile.incidents.incidenttable.assigned}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'last_status_time')" :class="{active: actionRequiredSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in actionRequiredItems" :key="index" @click="toIncident(item.id_incident)" :class="{watched: item.watchers}">
                                            <td style="position: relative;">
                                                <span class="id_incident">{{item.id_incident}}</span>
                                                <span class="post-it chatcount" v-if="item.chat_count" data-testid="chatCountLabel" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                                <span class="post-it urgent" v-if="item.urgency_level" style="background: orange;" v-tooltip.right="'Urgent'"><i class="fa-regular fa-circle-exclamation"></i></span>
                                                <span class="additional" v-if="item.passedIncident || item.passedToIncident || item.autodispatch || item.id_ori_incident"><i class="fas fa-info-circle"></i></span>
                                                <div class="additional-wrap">
                                                    <span class="additional-item" v-if="item.passedIncident"><i class="fas fa-share"></i> {{profile.dashboard.forwarded}}</span>
                                                    <span class="additional-item" v-if="item.passedToIncident"> <i class="fas fa-paper-plane"></i> {{profile.dashboard.put_through}} {{item.building_manager}}</span>
                                                    <span class="additional-item" v-if="item.autodispatch"><i class="fas fa-forward"></i> {{profile.dashboard.auto_forward}}</span>
                                                    <span class="additional-item" v-if="item.id_ori_incident"><i class="fas fa-copy" style="margin-right: 5px;"></i>{{profile.dashboard.copy_of}} {{item.id_ori_incident}}</span>
                                                </div>
                                                <div class="labels">
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                                </div>
                                            </td>
                                            <td v-tooltip.right="item.description">{{item.address}}</td>
                                            <td v-tooltip.right="item.description">{{item.asset}}</td>
                                            <td class="status" v-if="!profile['statusses'][item.status].repaircompany" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status]}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                            </td>
                                            <td class="status" v-if="profile['statusses'][item.status].repaircompany" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status].repaircompany}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span style="font-size: 10px;" v-if="item.status === 'returned_to_buildingmanager' && item.mandate_warning === 'no_warning'"> ({{profile.invoicing.refused}})</span>
                                            </td>
                                            <td class="status watch" v-show="item.watchers" v-tooltip.right="profile.dashboard.watched_by + ' ' + item.watchers"><i class="fas fa-eye"></i> {{profile.dashboard.watched}}</td>
                                            <td v-tooltip.right="item.description">{{item.rep_service_by_id_shiro_user_name}}<span v-if="item.team_name">,</span> {{item.team_name}}</td>
                                            <td v-tooltip.right="item.description" :class="item.overtimeStatus">{{item.overtime}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Current Incident Items -->
                <div class="items-wrap">
                    <div class="row" :class="{load: currentIncidentsLoading}">
                        <div class="col">
                            <div class="action-head">
                                <h3 data-testid="dashboardCurrentCount" class="action-indicator" v-if="currentIncidentItems.length || currentIncidentsLoading">{{profile.dashboard.building_manager.current_incidents_rep}} <span v-if="currentIncidentsCount">({{currentIncidentsCount}})</span></h3>
                                <div class="view-switch" v-if="currentIncidentsCount">
                                    <span class="table-switch" @click="switchView('currentincidents', 'table')" :class="{active: currentIncidentsView === 'table'}" v-tooltip.right="profile.dashboard.table_view"><i class="fa-light fa-table"></i></span>
                                    <span class="grid-switch"  @click="switchView('currentincidents', 'grid')" :class="{active: currentIncidentsView === 'grid'}" v-tooltip.right="profile.dashboard.list_view"><i class="fa-regular fa-grid"></i></span>
                                </div>
                                <div class="pagination" v-if="currentIncidentsCount > 9" :class="{'no-margin': currentIncidentsCount < 9}">
                                    <span class="prev" v-if="currentIncidentCurrentPage !== 1" @click="prevPage('currentincident')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="currentIncidentCurrentPage !== 1" @click="firstPage('currentincident')">1</span>
                                    <span class="page-indicator">{{currentIncidentCurrentPage}} / {{currentIncidentTotalPages}}</span>
                                    <span class="page page--last" v-if="currentIncidentTotalPages > 2" @click="lastPage('currentincident')">{{currentIncidentTotalPages}}</span>
                                    <span class="next" @click="nextPage('currentincident')" v-if="currentIncidentCurrentPage !== currentIncidentTotalPages"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="@/assets/icons/loading.gif" v-if="currentIncidentsLoading" class="loader" />
                    <div class="row" v-show="currentIncidentsView === 'grid'" :class="{hiddenxs: !showType}" v-if="currentIncidentsCount && !currentIncidentsLoading">
                        <div class="col">
                            <span class="sort"><i class="fa-regular fa-arrow-up-arrow-down"></i> {{profile.dashboard.building_manager.sort}}</span>
                            <div class="filters">
                                <span data-testid="dashboardCurrentSortId" class="filter hidden-xs" @click="sortTable('currentincident', 'id_incident')" :class="{active: currentIncidentSortType === 'id_incident', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.invoicing.job}} <i class="fa fa-chevron-down"></i></span>
                                <span data-testid="dashboardCurrentSortAddress" class="filter hidden-xs" @click="sortTable('currentincident', 'address')" :class="{active: currentIncidentSortType === 'address', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.address}} <i class="fa fa-chevron-down"></i></span>
                                <span data-testid="dashboardCurrentSortAsset" class="filter hidden-xs" @click="sortTable('currentincident', 'asset')" :class="{active: currentIncidentSortType === 'asset', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.asset}} <i class="fa fa-chevron-down"></i></span>
                                <span data-testid="dashboardCurrentSortStatus" class="filter" @click="sortTable('currentincident', 'status')" :class="{active: currentIncidentSortType === 'status', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.status}} <i class="fa fa-chevron-down"></i></span>
                                <span data-testid="dashboardCurrentSortAssigned" class="filter hidden-xs" @click="sortTable('currentincident', 'rep_service_by_id_shiro_user_name')" :class="{active: currentIncidentSortType === 'rep_service_by_id_shiro_user_name', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.assigned}} <i class="fa fa-chevron-down"></i></span>
                                <span data-testid="dashboardCurrentSortCreated" class="filter hidden-xs" @click="sortTable('currentincident', 'created')" :class="{active: currentIncidentSortType === 'created', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.datetime}} <i class="fa fa-chevron-down"></i></span>
                                <span data-testid="dashboardCurrentSortSince" class="filter" @click="sortTable('currentincident', 'last_status_time')" :class="{active: currentIncidentSortType === 'last_status_time', asc: currentIncidentsSortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_in_status}} <i class="fa fa-chevron-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-view" v-show="currentIncidentsView === 'grid'" :class="{hiddenxs: !showType}" v-if="currentIncidentsCount && !currentIncidentsLoading">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4" v-for="(item, index) in currentIncidentItems" :key="index" v-tooltip.right="item.description" @click="toIncident(item.id_incident)">
                                <div class="incident-wrap" :class="{watched: item.watchers}">
                                    <div class="block block--action">
                                        <div class="block-head">
                                            <span data-testid="dashboardCurrentStatus" class="action-status" v-if="!profile['statusses'][item.status].repaircompany">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status]}}
                                            </span>
                                            <span data-testid="dashboardCurrentStatus2" class="action-status" v-if="profile['statusses'][item.status].repaircompany">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status].repaircompany}}
                                            </span>
                                            <span data-testid="dashboardCurrentWatched" class="action-status watch" v-show="item.watchers" v-tooltip.right="profile.dashboard.watched_by + ' ' + item.watchers"><i class="fas fa-eye"></i> {{profile.dashboard.watched}}</span>
                                            <span data-testid="dashboardCurrentId" class="number">
                                                <i class="fas fa-share" style="font-size: 14px;" v-if="item.passedIncident" v-tooltip.right="profile.incident_detail.passed_tooltip"></i>
                                                <i class="fas fa-paper-plane" style="font-size: 14px;" v-if="item.passedToIncident" v-tooltip.right="profile.incident_detail.passed_to_tooltip_to + ' ' + item.building_manager"></i>
                                                {{ item.id_incident}}
                                            </span>
                                        </div>
                                        <div class="block-content">
                                            <span class="post-it urgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fa-regular fa-circle-exclamation"></i></span>
                                            <span class="post-it chatcount" v-if="item.chat_count" data-testid="chatCountLabel" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                            <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>     
                                            <div class="labels">
                                                <span data-testid="dashboardCurrentTeam" v-if="item.team_name" class="current-user"><i class="fas fa-users"></i> {{item.team_name}}</span>
                                                <span data-testid="dashboardCurrentUser" v-if="item.rep_service_by_id_shiro_user_name" class="current-user" v-tooltip.right="profile.incident_detail.incident_who + ' ' + item.rep_service_by_id_shiro_user_name"><i class="fas fa-user"></i> {{item.rep_service_by_id_shiro_user_name}}</span>
                                                <span data-testid="dashboardCurrentAuto" class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                <span data-testid="dashboardCurrentAuto2" class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                            </div>
                                            <h3 data-testid="dashboardCurrentManager" style="font-size: 15px; margin-bottom: 5px;">{{item.building_manager}}</h3>
                                            <h4 data-testid="dashboardCurrentAddress">{{item.address}}</h4>
                                            <h5 data-testid="dashboardCurrentAsset">{{item.asset}}</h5>
                                            <h6 data-testid="dashboardCurrentSpecialist" v-if="item.repair_company"><i class="fas fa-tools"></i> {{item.repair_company}} <span v-if="item.mechanic">| {{item.mechanic}}</span></h6>
                                            <h6 data-testid="dashboardCurrentManager2" v-if="item.id_building_manager !== item.original_id_building_manager && !item.repair_company"><i class="fas fa-tools"></i> {{item.building_manager}}</h6>
                                            <span data-testid="dashboardCurrentSince" class="time-indicator" :class="item.overtimeStatus">{{item.overtime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-view" v-show="currentIncidentsView === 'table'" v-if="currentIncidentsCount">
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('currentincident', 'id_incident')" :class="{active: currentIncidentSortType === 'id_incident'}">{{profile.invoicing.job}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'address')" :class="{active: currentIncidentSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'asset')" :class="{active: currentIncidentSortType === 'asset'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'status')" :class="{active: currentIncidentSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'rep_service_by_id_shiro_user_name')" :class="{active: currentIncidentSortType === 'rep_service_by_id_shiro_user_name'}">{{profile.incidents.incidenttable.assigned}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'mechanic')" :class="{active: currentIncidentSortType === 'mechanic'}">{{profile.incidents.incidenttable.mechanic}} <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'date')" :class="{active: currentIncidentSortType === 'date'}">{{profile.incidents.incidenttable.datetime}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('currentincident', 'last_status_time')" :class="{active: currentIncidentSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in currentIncidentItems" :key="index" @click="toIncident(item.id_incident)" v-tooltip.right="item.description">
                                            <td style="position: relative;">{{item.id_incident}}
                                                <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                                <div>
                                                    <span class="post-it urgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fa-regular fa-circle-exclamation"></i></span>
                                                    <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                                    <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                                </div>
                                            </td>                                         
                                            <td>{{item.address}}</td>
                                            <td>{{item.asset}}</td>
                                            <td v-if="!profile['statusses'][item.status].repaircompany">
                                                {{profile['statusses'][item.status]}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td v-if="profile['statusses'][item.status].repaircompany">
                                                {{profile['statusses'][item.status].repaircompany}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td>{{item.rep_service_by_id_shiro_user_name}}</td>
                                            <td>{{item.mechanic}}</td>
                                            <td>{{item.date}} {{item.time}}</td>
                                            <td :class="item.overtimeStatus">{{item.overtime}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>             
                </div>
                 <!-- Technically ready Items -->
                 <div class="items-wrap" :class="{load: technicalReadyLoading}">
                    <div class="row" :class="{hiddenxs: !showType}">
                        <div class="col">
                            <div class="action-head">
                                <h3 class="action-indicator" v-if="technicalReadyItems.length || technicalReadyLoading">{{profile.incident_detail.technical_ready}} <span v-if="technicalReadyCount">({{technicalReadyCount}})</span></h3>
                                <div class="view-switch" v-if="technicalReadyCount">
                                    <span class="table-switch" @click="switchView('technicalready', 'table')" :class="{active: technicalReadyView === 'table'}" v-tooltip.right="profile.dashboard.table_view"><i class="fa-light fa-table"></i></span>
                                    <span class="grid-switch"  @click="switchView('technicalready', 'grid')" :class="{active: technicalReadyView === 'grid'}" v-tooltip.right="profile.dashboard.list_view"><i class="fa-solid fa-grid"></i></span>
                                </div>
                                <div class="pagination" v-if="technicalReadyCount > 9" :class="{'no-margin': technicalReadyCount < 9}">
                                    <span class="prev" v-if="technicalReadyCurrentPage !== 1" @click="prevPage('technicalready')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="technicalReadyCurrentPage !== 1" @click="firstPage('technicalready')">1</span>
                                    <span class="page-indicator">{{technicalReadyCurrentPage}} / {{technicalReadyTotalPages}}</span>
                                    <span class="page page--last" v-if="technicalReadyTotalPages > 2" @click="lastPage('technicalready')">{{technicalReadyTotalPages}}</span>
                                    <span class="next" @click="nextPage('technicalready')" v-if="technicalReadyCurrentPage !== technicalReadyTotalPages"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="@/assets/icons/loading.gif" v-if="technicalReadyLoading" class="loader" />
                    <div class="row" v-show="technicalReadyView === 'grid'" :class="{hiddenxs: !showType}" v-if="technicalReadyItems.length && !technicalReadyLoading">
                        <div class="col">
                            <span class="sort"><i class="fa-regular fa-arrow-up-arrow-down"></i> {{profile.dashboard.building_manager.sort}}</span>
                            <div class="filters">
                                <span class="filter hidden-xs" @click="sortTable('technicalready', 'id_incident')" :class="{active: technicalReadySortType === 'id_incident', asc: technicalReadySortOrder === 'asc', desc: technicalReadySortOrder === 'desc'}">{{profile.invoicing.job}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('technicalready', 'address')" :class="{active: technicalReadySortType === 'address', asc: technicalReadySortOrder === 'asc', desc: technicalReadySortOrder === 'desc'}">{{profile.incidents.incidenttable.address}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('technicalready', 'asset')" :class="{active: technicalReadySortType === 'asset', asc: technicalReadySortOrder === 'asc', desc: technicalReadySortOrder === 'desc'}">{{profile.incidents.incidenttable.asset}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" @click="sortTable('technicalready', 'status')" :class="{active: technicalReadySortType === 'status', asc: technicalReadySortOrder === 'asc', desc: technicalReadySortOrder === 'desc'}">{{profile.incidents.incidenttable.status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('technicalready', 'rep_service_by_id_shiro_user_name')" :class="{active: technicalReadySortType === 'rep_service_by_id_shiro_user_name', asc: technicalReadySortOrder === 'asc', desc: currentIncidentsSortOrder === 'desc'}">{{profile.incidents.incidenttable.assigned}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" @click="sortTable('technicalready', 'created')" :class="{active: technicalReadySortType === 'created', asc: technicalReadySortOrder === 'asc', desc: technicalReadySortOrder === 'desc'}">{{profile.incidents.incidenttable.datetime}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" @click="sortTable('technicalready', 'last_status_time')" :class="{active: technicalReadySortType === 'last_status_time', asc: technicalReadySortOrder === 'asc', desc: technicalReadySortOrder === 'desc'}">{{profile.incidents.incidenttable.time_in_status}} <i class="fa fa-chevron-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-view" v-show="technicalReadyView === 'grid'" :class="{hiddenxs: !showType}" v-if="technicalReadyItems.length && !technicalReadyLoading">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4" v-for="(item, index) in technicalReadyItems" :key="index" v-tooltip.right="item.description" @click="toIncident(item.id_incident)">
                                <div class="incident-wrap" :class="{watched: item.watchers}">
                                    <div class="block block--action">
                                        <div class="block-head">
                                            <span class="action-status" v-if="!profile['statusses'][item.status].repaircompany">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status]}}
                                            </span>
                                            <span class="action-status" v-if="profile['statusses'][item.status].repaircompany">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status].repaircompany}}
                                            </span>
                                            <span class="action-status watch" v-show="item.watchers" v-tooltip.right="profile.dashboard.watched_by + ' ' + item.watchers"><i class="fas fa-eye"></i> {{profile.dashboard.watched}}</span>
                                            <span class="number" data-testid="dashboardTechnicalId">
                                                <i class="fas fa-share" style="font-size: 14px;" v-if="item.passedIncident" v-tooltip.right="profile.incident_detail.passed_tooltip"></i>
                                                <i class="fas fa-paper-plane" style="font-size: 14px;" v-if="item.passedToIncident" v-tooltip.right="profile.incident_detail.passed_to_tooltip_to + ' ' + item.building_manager"></i>
                                                {{ item.id_incident}}
                                            </span>
                                        </div>
                                        <div class="block-content" style="height: 140px;">
                                            <span class="post-it urgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fa-regular fa-circle-exclamation"></i></span>
                                            <span class="post-it chatcount" v-if="item.chat_count" data-testid="chatCountLabel"  v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                            <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                    <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                                </div>
                                            </div>
                                            <div class="labels">
                                                <span v-if="item.team_name" class="current-user"><i class="fas fa-users"></i> {{item.team_name}}</span>
                                                <span v-if="item.rep_service_by_id_shiro_user_name" class="current-user" v-tooltip.right="profile.incident_detail.incident_who + ' ' + item.rep_service_by_id_shiro_user_name"><i class="fas fa-user"></i> {{item.rep_service_by_id_shiro_user_name}}</span>
                                            </div>
                                            <h3 style="font-size: 15px; margin-bottom: 5px;">{{item.building_manager}}</h3>
                                            <h4>{{item.address}}</h4>
                                            <h5>{{item.asset}}</h5>
                                            <h6 v-if="item.repair_company"><i class="fas fa-tools"></i> {{item.repair_company}} <span v-if="item.mechanic">| {{item.mechanic}}</span></h6>
                                            <h6 v-if="item.id_building_manager !== item.original_id_building_manager && !item.repair_company"><i class="fas fa-tools"></i> {{item.building_manager}}</h6>
                                            <span class="time-indicator" :class="item.overtimeStatus">{{item.overtime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-view" v-show="technicalReadyView === 'table'" v-if="technicalReadyItems.length">
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('technicalready', 'id_incident')" :class="{active: technicalReadySortType === 'id_incident'}">{{profile.invoicing.job}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('technicalready', 'address')" :class="{active: technicalReadySortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('technicalready', 'asset')" :class="{active: technicalReadySortType === 'asset'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('technicalready', 'status')" :class="{active: technicalReadySortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('technicalready', 'rep_service_by_id_shiro_user_name')" :class="{active: technicalReadySortType === 'rep_service_by_id_shiro_user_name'}">{{profile.incidents.incidenttable.assigned}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('technicalready', 'mechanic')" :class="{active: technicalReadySortType === 'mechanic'}">{{profile.incidents.incidenttable.mechanic}} <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('technicalready', 'date')" :class="{active: technicalReadySortType === 'date'}">{{profile.incidents.incidenttable.datetime}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('technicalready', 'last_status_time')" :class="{active: technicalReadySortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in technicalReadyItems" :key="index" @click="toIncident(item.id_incident)" v-tooltip.right="item.description">
                                            <td style="position: relative;">{{item.id_incident}}
                                                <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                                <div>
                                                    <span class="post-it urgent" v-if="item.urgency_level" v-tooltip.right="'Urgent'"><i class="fa-regular fa-circle-exclamation"></i></span>
                                                    <span class="post-it chatcount" v-if="item.chat_count" v-tooltip.right="item.chat_count + ' ' + profile.dashboard.building_manager.unread_messages">{{item.chat_count}}</span>
                                                    <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                                </div>
                                            </td> 
                                            <td>{{item.address}}</td>
                                            <td>{{item.asset}}</td>
                                            <td v-if="!profile['statusses'][item.status].repaircompany">
                                                {{profile['statusses'][item.status]}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td v-if="profile['statusses'][item.status].repaircompany">
                                                {{profile['statusses'][item.status].repaircompany}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span v-if="item.status === 'planned'">({{item.appointment}})</span>
                                            </td>
                                            <td>{{item.rep_service_by_id_shiro_user_name}}</td>
                                            <td>{{item.mechanic}}</td>
                                            <td>{{item.date}} {{item.time}}</td>
                                            <td :class="item.overtimeStatus">{{item.overtime}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Closed incidents -->
                <div class="row hidden-xs" v-if="closedIncidentItems.length">
                    <div class="col">
                        <div class="action-head">
                            <h3 data-testid="dashboardClosedCount" class="action-indicator" @click="showClosed = !showClosed" style="cursor: pointer;">{{profile.dashboard.building_manager.closed_incidents_rep}} ({{closedIncidentsCount}})</h3>
                            <i class="fas fa-chevron-down toggleclosed" @click="showClosed = !showClosed" :class="{active: showClosed}"></i>
                            <div class="pagination" style="margin-top: 5px;" v-if="closedIncidentTotalPages >= 1 && showClosed">
                                <span class="prev" v-if="closedIncidentCurrentPage !== 1" @click="prevPage('closedincident')"><i class="fas fa-chevron-left"></i></span>
                                <span class="next" @click="nextPage('closedincident')" v-if="closedIncidentCurrentPage !== closedIncidentTotalPages" style="border-left: none;"><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-view hidden-xs" v-if="showClosed && closedIncidentItems.length">
                    <div class="row">
                        <div class="col">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" @click="sortTable('closedincident', 'id_incident')" :class="{active: closedIncidentSortType === 'id_incident'}">{{profile.invoicing.job}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'address')" :class="{active: closedIncidentSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'asset')" :class="{active: closedIncidentSortType === 'asset'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'status')" :class="{active: closedIncidentSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'building_manager')" :class="{active: closedIncidentSortType === 'repair_company'}">{{profile.incidents.incidenttable.client}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'date')" :class="{active: closedIncidentSortType === 'date'}">{{profile.incidents.incidenttable.datetime}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'last_status_time')" :class="{active: closedIncidentSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in closedIncidentItems" :key="index" @click="toIncident(item.id_incident)" v-tooltip.right="item.description">
                                        <td data-testid="dashboardClosedId">{{item.id_incident}}
                                            <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                            <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                        </td>
                                        <td data-testid="dashboardClosedAddress">{{item.address}}</td>
                                        <td data-testid="dashboardClosedAsset">{{item.asset}}</td>
                                        <td v-if="!profile['statusses'][item.status].repaircompany">
                                            {{profile['statusses'][item.status]}}
                                            <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                        </td>
                                        <td v-if="profile['statusses'][item.status].repaircompany">
                                            {{profile['statusses'][item.status].repaircompany}}
                                            <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                        </td>
                                        <td data-testid="dashboardClosedManager">{{item.building_manager}}</td>
                                        <td data-testid="dashboardClosedTime">{{item.date}} {{item.time}}</td>
                                        <td :class="item.overtimeStatus" data-testid="dashboardClosedSince">{{item.since}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import { EventBus } from '@/js/event-bus.js';
import moment from 'moment';
import debounce from 'lodash/debounce';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            userName: '',
            bmCompanyName: '',
            actionRequiredView: 'grid',
            currentIncidentsView: 'table',
            actionRequiredItems: [],
            actionRequiredSortType: '',
            actionRequiredCurrentPage: 1,
            actionRequiredTotalPages: 1,
            currentIncidentItems: [],
            currentIncidentSortType: '',
            currentIncidentCurrentPage: 1,
            currentIncidentTotalPages: 1,
            actionRequiredCount: 0,
            currentIncidentsCount: 0,
            closedIncidentsCount: 0,
            closedIncidentItems: [],
            closedIncidentSortType: 'last_status_time',
            closedIncidentCurrentPage: 1,
            closedIncidentTotalPages: 1,
            suggestOpen: true,
            searchTerm: '',
            searchSuggestions: [],
            totalSuggestions: 0,
            newIncidentModalOpen: false,
            actionRequiredSortOrder: '',
            currentIncidentsSortOrder: '',
            closedIncidentsSortOrder: 'desc',
            newStickersModalOpen: false,
            excelModalOpen: false,
            sortOrder: 'asc',
            profilePic: '',
            settings: {},
            searchTerms: [],
            showType: false,
            showClosed: false,
            buildingmanagerList: [],
            buildingManagerDropdownToggled: false,
            technicalReadyView: 'grid',
            technicalReadyCount: 0,
            technicalReadyItems: [],
            technicalReadySortType: 'last_status_time',
            technicalReadyCurrentPage: 1,
            technicalReadyTotalPages: 1,
            technicalReadySortOrder: 'desc',
            actionrequiredLoading: true,
            currentIncidentsLoading: true,
            technicalReadyLoading: true
        }
    },
    created() {
        this.applySettings();
        document.title = 'S1MONE | Dashboard';
        let self = this;
        fetch('/api/v1/repaircompanystats')
        .then(response => { response.json().then(
            function(data) {
                self.profilePic = data.repair_company.profile_image_loc;
                self.bmCompanyName = data.repair_company.name;
                self.getCurrentUser();
                self.getBmList(); 
            });
        });
        EventBus.$on('langChange', busObj => {
            for (var i = 0; i < this.actionRequiredItems.length; i++) {
                let item = this.actionRequiredItems[i];
                if (busObj.locale === 'nl') {
                    item.date = moment(item.created).locale(busObj.locale).format('D MMMM YYYY');
                }
                if (busObj.locale === 'en') {
                    item.date = moment(item.created).locale(busObj.locale).format('MMMM D YYYY');
                }
            }
            for (var j = 0; j < this.currentIncidentItems.length; j++) {
                let item = this.currentIncidentItems[j];
                if (busObj.locale === 'nl') {
                    item.date = moment(item.created).locale(busObj.locale).format('D MMMM YYYY');
                }
                if (busObj.locale === 'en') {
                    item.date = moment(item.created).locale(busObj.locale).format('MMMM D YYYY');
                }
            }
        });
        setInterval(function () {
            this.getActionRequiredItems();
            this.getCurrentIncidentItems();
            this.getClosedIncidentItems();
            this.getTechnicalReadyItems();
            this.applySettings();
        }.bind(this), 300000);
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        applySettings() {
            // Apply LocalStorage settings to vue instance
            if (localStorage.getItem('S1-settings') !== null) {
                let settings = localStorage.getItem('S1-settings');
                settings = JSON.parse(settings);
                this.settings = settings;
                this.actionRequiredView = this.settings.actionRequiredView;
                this.currentIncidentsView = this.settings.currentIncidentsView;
                if (this.settings.actionRequiredSortOrder) {
                    this.actionRequiredSortOrder = this.settings.actionRequiredSortOrder;
                }
                else {
                    this.actionRequiredSortOrder ='asc';
                    this.settings.actionRequiredSortOrder ='asc';
                }
                if (this.settings.currentIncidentsSortOrder) {
                    this.currentIncidentsSortOrder = this.settings.currentIncidentsSortOrder;
                }
                else {
                    this.currentIncidentsSortOrder = 'asc';
                    this.settings.currentIncidentsSortOrder ='asc';
                }
                this.actionRequiredSortType = this.settings.actionRequiredSort;
                this.currentIncidentSortType = this.settings.currentIncidentsSort;
                this.actionRequiredCurrentPage = this.settings.actionRequiredCurrentPage;
                this.currentIncidentCurrentPage = this.settings.currentIncidentCurrentPage;
                this.showClosed = this.settings.showClosed;
                if (!this.settings.technicalReadyView) {
                    this.settings.technicalReadyView = 'grid';
                }
                if (!this.settings.technicalReadySortOrder) {
                    this.settings.technicalReadySortOrder = 'asc';
                }
                if (!this.settings.technicalReadyCurrentPage) {
                    this.settings.technicalReadyCurrentPage = 1;
                }
                if (!this.settings.filters) {
                    this.settings.filters = {};
                }
                if (!this.settings.checkboxes) {
                    this.settings.checkboxes = {};
                }
            }
            else {
                this.settings.actionRequiredView = 'grid';
                this.settings.currentIncidentsView = 'grid';
                this.settings.actionRequiredSort = 'id_incident';
                this.settings.currentIncidentsSort = 'id_incident';
                this.settings.actionRequiredSortOrder = 'asc';
                this.settings.currentIncidentsSortOrder = 'asc';
                this.settings.currentIncidentCurrentPage = 1;
                this.settings.actionRequiredCurrentPage = 1;
                this.settings.technicalReadyView = 'grid';
                this.settings.technicalReadySort = 'id_incident';
                this.settings.technicalReadySortOrder = 'asc';
                this.settings.technicalReadyCurrentPage = 1;
                this.actionRequiredSortType = this.settings.actionRequiredSort;
                this.currentIncidentSortType = this.settings.currentIncidentsSort;
                this.actionRequiredCurrentPage = this.settings.actionRequiredCurrentPage;
                this.currentIncidentCurrentPage = this.settings.currentIncidentCurrentPage;
                this.technicalReadyCurrentPage = this.settings.technicalReadyCurrentPage;
                this.settings.filters = {};
                this.showClosed = true;
                localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            }
        },
        getCurrentUser() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
            .then(response => { response.json().then(
                function(data) {
                    self.userName = data.name;
                    if (self.settings.userName !== data.username) {
                        self.searchTerms = [];
                        self.settings.userName = data.username;
                        self.settings.actionRequiredView = 'grid';
                        self.settings.currentIncidentsView = 'grid';
                        self.settings.actionRequiredSort = 'last_status_time';
                        self.settings.currentIncidentsSort = 'last_status_time';
                        self.settings.actionRequiredSortOrder = 'desc';
                        self.settings.currentIncidentsSortOrder = 'desc';
                        self.settings.currentIncidentCurrentPage = 1;
                        self.settings.actionRequiredCurrentPage = 1;
                    }
                    else {
                        if (localStorage.getItem('S1-searchterms')) {
                            let searchTerms = localStorage.getItem('S1-searchterms');
                            searchTerms = JSON.parse(searchTerms);
                            self.searchTerms = searchTerms;
                            for (var i = 0; i < self.searchTerms.length; i++) {
                                let searchTerm = self.searchTerms[i];
                                if (searchTerm === "") {
                                    self.searchTerms.splice(i, 1);
                                }
                            }
                        }
                    }
                    localStorage.setItem('S1-settings', JSON.stringify(self.settings));
                    localStorage.setItem('S1-searchterms', JSON.stringify(self.searchTerms));
                    self.getActionRequiredItems();
                    self.getCurrentIncidentItems();
                    self.getClosedIncidentItems();
                    self.getTechnicalReadyItems();
                });
            });
        },
        getBmList() {
            let self = this;
            fetch('api/v1/incidentcombined/availablefilters')
            .then(response => { response.json().then(
                function(data) {
                    self.buildingmanagerList = data.available_building_managers;
                });
            });
        },
        getActionRequiredItems() {
            this.actionRequiredCount = 0;
            this.actionrequiredLoading = true;
            let orderParam = '?sort_dir=' + this.settings.actionRequiredSortOrder;
            let sortParam = '';
            if (this.settings.actionRequiredSort) {
                sortParam = '&sort_col=' + this.settings.actionRequiredSort;
            }
            let filterParam = '';     
            if (this.settings.filters.buildingmanager) {
                filterParam = '&id_building_manager=' + this.settings.filters.buildingmanager;
            }
            let offSetParam = this.settings.actionRequiredCurrentPage - 1;
            if (offSetParam < 0) {
                offSetParam = 0;
            }
            offSetParam = offSetParam * 9;
            let self = this;
            fetch('/api/v1/incidentcombined/actionrequiredrepaircompany' + orderParam + sortParam + filterParam + '&limit=9&offset=' + offSetParam)
            .then(response => { response.json().then(
                function(data) {
                    self.actionRequiredItems = [];
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.created).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.created).locale('nl').format('LT');
                        item.createdDate = moment(item.created).locale('nl').format('D MMMM YYYY, HH:mm');
                        item.type = 'actionrequired';
                        let created = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                        if (item.last_status_time) {
                            created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        }
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.overtime = differenceString;
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                    }
                    self.actionRequiredItems = items;
                    self.actionRequiredCount = data.recordsTotal;
                    self.actionRequiredItems = items;
                    let totalPagesRaw = (self.actionRequiredCount + 1) / 9;
                    self.actionRequiredTotalPages = Math.ceil(totalPagesRaw);
                    if (self.actionRequiredCurrentPage > self.actionRequiredTotalPages) {
                        self.actionRequiredCurrentPage = self.actionRequiredTotalPages;
                        self.settings.actionRequiredCurrentPage = self.actionRequiredCurrentPage;
                    }
                    if (self.actionRequiredCount < 10 && self.settings.actionRequiredCurrentPage > 1) {
                        self.actionRequiredCurrentPage = 1;
                        self.actionRequiredTotalPages = 1;
                        self.settings.actionRequiredCurrentPage = 1;
                        self.getActionRequiredItems();
                    }
                    self.actionrequiredLoading = false;
                });
            });
        },
        getCurrentIncidentItems() {
            this.currentIncidentsCount = 0;
            this.currentIncidentsLoading = true;
            let orderParam = '?sort_dir=' + this.settings.currentIncidentsSortOrder;
            let sortParam = '';
            if (this.settings.currentIncidentsSort) {
                sortParam = '&sort_col=' + this.settings.currentIncidentsSort;
            }
            let filterParam = '';     
            if (this.settings.filters.buildingmanager) {
                filterParam = '&id_building_manager=' + this.settings.filters.buildingmanager;
            }
            let offSetParam = this.settings.currentIncidentCurrentPage - 1;
            if (offSetParam < 0) {
                offSetParam = 0;
            }
            offSetParam = offSetParam * 9;
            let self = this;
            fetch('/api/v1/incidentcombined/openrepaircompany' + orderParam + sortParam + filterParam + '&limit=9&offset=' + offSetParam)
            .then(response => { response.json().then(
                function(data) {
                    let items = [];
                    self.currentIncidentItems = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.last_status_time).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.last_status_time).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.last_status_time).locale('nl').format('LT');
                        item.createdDate = moment(item.created).locale('nl').format('D MMMM YYYY, HH:mm');
                        item.type = 'currentincidents';
                        let created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.overtime = differenceString;
                        item.appointment =   moment(item.start_time).format('DD/MM/YYYY HH:mm');
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                    }
                    self.currentIncidentItems = items;
                    self.currentIncidentsCount = data.recordsTotal;
                    self.currentIncidentItems = items;
                    let totalPagesRaw = (self.currentIncidentsCount + 1) / 9;
                    self.currentIncidentTotalPages = Math.ceil(totalPagesRaw);
                    if (self.currentIncidentCurrentPage > self.currentIncidentTotalPages) {
                        self.currentIncidentCurrentPage = self.currentIncidentTotalPages;
                        self.settings.currentIncidentCurrentPage = self.currentIncidentCurrentPage;
                    }
                    if (self.currentIncidentsCount < 10 && self.settings.currentIncidentCurrentPage > 1) {
                        self.currentIncidentCurrentPage = 1;
                        self.currentIncidentTotalPages = 1;
                        self.settings.currentIncidentCurrentPage = 1;
                        self.getCurrentIncidentItems();
                    }
                    self.currentIncidentsLoading = false;
                });
            });
        },
        getTechnicalReadyItems() {
            this.technicalReadyCount = 0;
            this.technicalReadyLoading = true;
            let orderParam = '?sort_dir=' + this.settings.technicalReadySortOrder;
            let sortParam = '';
            if (this.settings.technicalReadySort) {
                sortParam = '&sort_col=' + this.settings.technicalReadySort;
            }
            let filterParam = '';     
            if (this.settings.filters.buildingmanager) {
                filterParam = '&id_building_manager=' + this.settings.filters.buildingmanager;
            }
            let offSetParam = this.settings.technicalReadyCurrentPage - 1;
            if (offSetParam < 0) {
                offSetParam = 0;
            }
            offSetParam = offSetParam * 9;
            let self = this;
            fetch('/api/v1/incidentcombined/closedadminopenrepaircompany' + orderParam + sortParam + filterParam + '&limit=9&offset=' + offSetParam)
            .then(response => { response.json().then(
                function(data) {
                    self.technicalReadyItems = [];
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.last_status_time).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.last_status_time).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.last_status_time).locale('nl').format('LT');
                        item.type = 'currentincidents';
                        let created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.overtime = differenceString;
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                    }
                    self.technicalReadyItems = items;
                    self.technicalReadyCount = data.recordsTotal;
                    let totalPagesRaw = (self.technicalReadyCount + 1) / 9;
                    self.technicalReadyTotalPages = Math.ceil(totalPagesRaw);
                    if (self.technicalReadyCurrentPage > self.technicalReadyTotalPages) {
                        self.technicalReadyCurrentPage = self.technicalReadyTotalPages;
                        self.settings.technicalReadyCurrentPage = self.technicalReadyCurrentPage;
                    }
                    if (self.technicalReadyCount < 10 && self.settings.technicalReadyCurrentPage > 1) {
                        self.technicalReadyCurrentPage = 1;
                        self.technicalReadyTotalPages = 1;
                        self.settings.technicalReadyCurrentPage = 1;
                        self.getTechnicalReadyItems();
                    }
                    self.technicalReadyLoading = false;
                });
            });
        },
        getClosedIncidentItems() {
            this.closedIncidentsCount = 0;
            let orderParam = '&sort_dir=' + this.closedIncidentsSortOrder;
            let from;
            if (this.closedIncidentCurrentPage === 1) {
                from = 0;
            }
            else {
                from = (this.closedIncidentCurrentPage - 1) * 25;
            }
            let fromParam = '&offset=' + from;
            let sortParam = '';
            if (this.closedIncidentSortType) {
                sortParam = '&sort_col=' + this.closedIncidentSortType;
            }
            let self = this;
            fetch('/api/v1/incidentcombined/closedrepaircompany?limit=25'+ orderParam + fromParam  + sortParam)
            .then(response => { response.json().then(
                function(data) {
                    self.closedIncidentItems = [];
                    let items = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let locale = localStorage.getItem('S1-locale');
                        if (locale === 'nl') {
                            item.date = moment(item.last_status_time).locale('nl').format('D MMMM YYYY');
                        }
                        if (locale === 'en') {
                            item.date = moment(item.last_status_time).locale('en').format('MMMM D YYYY');
                        }
                        item.time = moment(item.last_status_time).locale('nl').format('LT');
                        item.type = 'closedincidents';
                        let created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                        let now = moment().format('DD/MM/YYYY HH:mm:ss');
                        let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                        let differenceObj = self.convertToDays(difference);
                        let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                        item.since = differenceString;
                        if (differenceObj.days < 1) {
                            item.overtimeStatus = 'green';
                        }
                        if (differenceObj.days >= 1) {
                            item.overtimeStatus = 'orange';
                        }
                        if (differenceObj.days > 3) {
                            item.overtimeStatus = 'red';
                        }
                        items.push(item);
                        self.closedIncidentsCount = i;
                    }
                    self.closedIncidentItems = items;
                    self.closedIncidentsCount = data.recordsTotal;
                    self.closedIncidentTotalPages = data.recordsTotal / 25;
                    self.closedIncidentTotalPages = Math.ceil(self.closedIncidentTotalPages);
                });
            });
        },
        searchSuggest: debounce(function (e) {
            this.searchSuggestions = [];
            if (typeof e === 'string') {
                this.searchTerm = e;
            }
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                let self = this;
                fetch('/api/v1/incidentcombined/search?search_term=' + searchTerm)
    	        .then(response => { response.json().then(
    	            function(data) {
                        self.totalSuggestions = data.data.length;
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (self.locale === 'nl') {
                                item.date = moment(item.last_status_time).locale('nl').format('D MMMM YYYY');
                            }
                            if (self.locale === 'en') {
                                item.date = moment(item.last_status_time).locale('en').format('MMMM D YYYY');
                            }
                            item.createdDate = moment(item.created).locale('nl').format('D MMMM YYYY, HH:mm');
                            item.time = moment(item.last_status_time).locale('nl').format('LT');
                            self.searchSuggestions.push(item);
                        }
    				});
    	        });
            }
            else {
                this.searchActive = false;
                this.searchSuggestions = [];
                this.totalSuggestions = 0;
            }
            if (e !== undefined) {
                if(e.keyCode === 27) {
                    this.searchActive = false;
                    this.searchTerm = '';
                    this.searchSuggestions = [];
                    this.totalSuggestions = 0;
                }
            }
        }, 500),
        removeTerm(item) {
            this.searchTerm = '';
            let index = this.searchTerms.indexOf(item);
            this.searchTerms.splice(index, 1);
            localStorage.setItem('S1-searchterms', JSON.stringify(this.searchTerms));
        },
        clearSuggestions() {
            this.totalSuggestions = 0;
            this.searchSuggestions = [];
            this.searchTerm = '';
        },
        switchView(type, view) {
            if (type === 'actionrequired') {
                if (view === 'grid') {
                    this.actionRequiredView = 'grid';
                    this.settings.actionRequiredView = 'grid';
                }
                if (view === 'table') {
                    this.actionRequiredView = 'table';
                    this.settings.actionRequiredView = 'table';
                }
            }
            if (type === 'currentincidents') {
                if (view === 'grid') {
                    this.currentIncidentsView = 'grid';
                    this.settings.currentIncidentsView = 'grid';

                }
                if (view === 'table') {
                    this.currentIncidentsView = 'table';
                    this.settings.currentIncidentsView = 'table';
                }
            }
            if (type === 'technicalready') {
                if (view === 'grid') {
                    this.technicalReadyView = 'grid';
                    this.settings.technicalReadyView = 'grid';

                }
                if (view === 'table') {
                    this.technicalReadyView = 'table';
                    this.settings.technicalReadyView = 'table';
                }
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        sortTable(type, sorter) {
            if (type === 'actionrequired') {
                if (this.settings.actionRequiredSort === sorter) {
                    this.actionRequiredSortOrder = this.actionRequiredSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.actionRequiredSortOrder = 'desc';
                }
                this.settings.actionRequiredSortOrder = this.actionRequiredSortOrder;
                this.settings.actionRequiredSort = sorter;
                this.actionRequiredSortType = sorter;
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                if (this.settings.currentIncidentsSort === sorter) {
                    this.currentIncidentsSortOrder = this.currentIncidentsSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.currentIncidentsSortOrder = 'desc';
                }
                this.settings.currentIncidentsSortOrder = this.currentIncidentsSortOrder;
                this.settings.currentIncidentsSort = sorter;
                this.currentIncidentSortType = sorter;
                this.getCurrentIncidentItems();
            }
            if (type === 'technicalready') {
                if (this.settings.technicalReadySort === sorter) {
                    this.technicalReadySortOrder = this.technicalReadySortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.technicalReadySortOrder = 'desc';
                }
                this.settings.technicalReadySortOrder = this.technicalReadySortOrder;
                this.settings.technicalReadySort = sorter;
                this.technicalReadySortType = sorter;
                this.getTechnicalReadyItems();
            }
            if (type === 'closedincident') {
                if (this.closedIncidentSortType === sorter) {
                    this.closedIncidentsSortOrder = this.closedIncidentsSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.closedIncidentsSortOrder = 'asc';
                }
                this.closedIncidentSortType = sorter;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        nextPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage++;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage++;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getCurrentIncidentItems();
            }
            if (type === 'technicalready') {
                this.technicalReadyCurrentPage++;
                this.settings.technicalReadyCurrentPage = this.technicalReadyCurrentPage;
                this.getTechnicalReadyItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage++;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        prevPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage--;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage--;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getCurrentIncidentItems();
            }
            if (type === 'technicalready') {
                this.technicalReadyCurrentPage--;
                this.settings.technicalReadyCurrentPage = this.technicalReadyCurrentPage;
                this.getTechnicalReadyItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage--;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        firstPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage = 1;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage = 1;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getCurrentIncidentItems();
            }
            if (type === 'technicalready') {
                this.technicalReadyCurrentPage = 1;
                this.settings.technicalReadyCurrentPage = this.technicalReadyCurrentPage;
                this.getTechnicalReadyItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage = 1;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        lastPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage = this.actionRequiredTotalPages;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            if (type === 'currentincident') {
                this.currentIncidentCurrentPage = this.currentIncidentTotalPages;
                this.settings.currentIncidentCurrentPage = this.currentIncidentCurrentPage;
                this.getCurrentIncidentItems();
            }
            if (type === 'technicalready') {
                this.technicalReadyCurrentPage = this.technicalReadyTotalPages;
                this.settings.technicalReadyCurrentPage = this.technicalReadyCurrentPage;
                this.getTechnicalReadyItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        toIncident(id) {
            if (this.searchTerms.length === 5) {
                this.searchTerms.splice(0, 1);
            }
            let searchTerm = this.searchTerm.toLowerCase();
            if (!this.searchTerms.includes(searchTerm)) {
                this.searchTerms.push(searchTerm);
                localStorage.setItem('S1-searchterms', JSON.stringify(this.searchTerms));
            }
            this.$router.push('/supplier/incident-detail?id=' + id);
        },
        convertToDays(milliSeconds){
            let days = Math.floor(milliSeconds/(86400 * 1000));
            milliSeconds -= days*(86400*1000);
            let hours = Math.floor(milliSeconds/(60 * 60 * 1000 ));
            milliSeconds -= hours * (60 * 60 * 1000);
            let minutes = Math.floor(milliSeconds/(60 * 1000));
            return {
                days, hours, minutes
            }
        },
        pad(n) {
            return n < 10 ? '0' + n : n
        },
        selectBm(bm) {
            this.buildingManagerDropdownToggled = false;
            this.showUrgentItems = false;
            this.settings.filters.buildingmanager = bm.id_building_manager;  
            this.settings.filters.buildingmanagerName = bm.name;                      
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getActionRequiredItems();
            this.getCurrentIncidentItems();
            this.getTechnicalReadyItems();
        },
        removeBm() {
            this.buildingManagerDropdownToggled = false;
            this.settings.filters = {};
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getActionRequiredItems();
            this.getCurrentIncidentItems();
            this.getTechnicalReadyItems();
        }
    }
}
</script>
